import React, { Component } from 'react';
import './ImageList.css';
import api from '../../api';
import { connect } from 'react-redux';
import { Container, withStyles, Snackbar, Button } from '@material-ui/core';
import MuiAlert from '@material-ui/lab/Alert';
import { getImages, deleteImage } from '../../actions';
import DeleteIcon from '@material-ui/icons/Delete';
const useStyles = {
  container: {
    paddingTop: '50px',
  },
  box: {
    border: '1px solid #e1e1e1',
  },
  formTitle: {
    // fontSize: "3rem",
    color: '#fff',
    padding: '10px 20px',
  },
  formContent: {
    padding: '40px',
  },
  input: {
    marginBottom: '30px',
  },
  fontSize: {
    // fontSize: "2rem",
  },
};

class ImageList extends Component {
  state = {
    pass: '',
    newPass: '',
    newPassConfirm: '',
    imageList: [],
  };

  componentDidMount() {
    let imageList = this.props.images;
    if (imageList.data) {
      this.setState({ imageList: imageList.data.list });
    } else {
      this.props.getImages();
    }
  }

  componentWillReceiveProps(nextProps) {
    if (JSON.stringify(nextProps.images) != JSON.stringify(this.props.images)) {
      let imageList = nextProps.images;
      if (imageList.data) {
        this.setState({ imageList: imageList.data.list });
      }
    }
  }

  render() {
    const { classes } = this.props;
    const { imageList } = this.state;

    return (
      <Container className={classes.container} maxWidth='lg'>
        <div className='imagegrid'>
          {imageList.map((item) => {
            return (
              <div className='griditem' key={item}>
                <span>{item}</span>{' '}
                <Button
                  startIcon={<DeleteIcon />}
                  variant='contained'
                  color='secondary'
                  fullWidth
                  onClick={() => {
                    this.props.deleteImage(item);
                  }}
                >
                  Delete
                </Button>
                <img src={`${api.baseUrl}/uploads/${item}`}></img>
              </div>
            );
          })}
        </div>
        <Snackbar
          open={this.state.showError}
          autoHideDuration={3000}
          onClose={() => this.setState({ showError: false })}
        >
          <MuiAlert elevation={6} variant='filled' severity='error'>
            {this.state.message}
          </MuiAlert>
        </Snackbar>
      </Container>
    );
  }
}
function mapStateToProps(state) {
  return {
    images: state.images,
  };
}
export default connect(mapStateToProps, { getImages, deleteImage })(
  withStyles(useStyles)(ImageList),
);
