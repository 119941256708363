import axios from "axios";
axios.defaults.baseURL = "https://devdb2.aseiko.io"; //prod
//axios.defaults.baseURL = "http://localhost:5000";
axios.interceptors.request.use(function(config) {
  const token = localStorage.getItem("aseiko_token");
  config.headers.Authorization = token;

  return config;
});
export default {
  baseUrl: axios.defaults.baseURL,
  user: {
    login: (username, password) =>
      axios
        .post("/user/login", { username, password })
        .then((res) => res.data)
        .catch((err) => err.response),
    token: (token) =>
      axios
        .post("/user/token", { token })
        .then((res) => res.data)
        .catch((err) => err.response),

    getUser: (userID) =>
      axios
        .get("/dashboard/user/" + userID)
        .then((res) => res.data)
        .catch((err) => err.response),
  },
  tables: {
    changePassword: (id, password) =>
      axios
        .post("/dashboard/changepassword", { id, password })
        .then((res) => res.data)
        .catch((err) => err.response),
    addUser: (username, password) =>
      axios
        .post("/dashboard/adduser", { username, password })
        .then((res) => res.data)
        .catch((err) => err.response),
    getTable: (tableName, page) =>
      axios
        .post("/dashboard/tables", { tableName, page })
        .then((res) => res.data)
        .catch((err) => err.response),
    getRowRelations: (mapData, identifier) =>
      axios
        .post("/dashboard/table_relations", { mapData, identifier })
        .then((res) => res.data)
        .catch((err) => err.response),
    addRow: (tableName, relations, mapData, data) =>
      axios
        .post("/dashboard/addrow", { tableName, relations, mapData, data })
        .then((res) => res.data)
        .catch((err) => err.response),
    updateRow: (tableName, data, relations, mapData, identifier) =>
      axios
        .post("/dashboard/updaterow", {
          tableName,
          data,
          relations,
          mapData,
          identifier,
        })
        .then((res) => res.data)
        .catch((err) => err.response),
    deleteRow: (tableName, mapData, identifier) =>
      axios
        .post("/dashboard/deleterow", {
          tableName,
          mapData,
          identifier,
        })
        .then((res) => res.data)
        .catch((err) => err.response),

    deleteMulti: (tableName, mapData, identifiers) =>
      axios
        .post("/dashboard/deletemulti", {
          tableName,
          mapData,
          identifiers,
        })
        .then((res) => res.data)
        .catch((err) => err.response),
    sendFile: (data) =>
      axios({
        method: "post",
        url: "/dashboard/uploadproductscsv",
        data,
        config: {
          headers: { "Content-Type": "multipart/form-data" },
        },
      })
        .then((res) => res.data)
        .catch((err) => err.response),
  },
  images: {
    deleteImages: (ids) =>
      axios
        .post("/dashboard/deleteimages", { ids })
        .then((res) => res.data)
        .catch((err) => err.response),
    getImages: () =>
      axios
        .get("/dashboard/listimages/")
        .then((res) => res.data)
        .catch((err) => err.response),
  },
  notifications: {
    sendNotifications: (data) =>
      axios
        .post("/dashboard/notifications", { data })
        .then((res) => res)
        .catch((err) => err),
  },
};
