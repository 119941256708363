import React, { Component, useRef, useState, useEffect } from "react";
import { DataGrid } from "@material-ui/data-grid";
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Button,
  TextField,
} from "@material-ui/core";
import { getNotifications } from "../../actions";

import "./index.css";
import FIRESTORE from "../../firestore";

const Notifications = () => {
  const [notifications, setNotifications] = useState([]);
  const [showAdd, setShowAdd] = useState(null);
  const [title, setTitle] = useState(null);
  const [description, setDesc] = useState(null);
  const [action, setAction] = useState(null);

  const selectedRows = useRef({});
  const fetchNotifications = async () => {
    let res = await getNotifications();

    setNotifications(res);
  };

  useEffect(() => {
    fetchNotifications();
  }, []);

  const currentlySelected = (selections) => {
    selectedRows.current = selections;
  };

  const columns = [
    { field: "id", headerName: "ID", width: 100, type: "string" },
    { field: "title", headerName: "Title", width: 150, type: "string" },
    {
      field: "description",
      headerName: "Description",
      width: 150,
      type: "string",
    },
  ];

  const renderAddForm = () => {
    return (
      <div style={{ height: 400, width: "100%", paddingLeft: "20px" }}>
        <div style={{ height: 400, width: "50%" }}>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <h4>Add New Notification</h4>

            <div className="input-wrapper">
              <TextField
                required
                id="standard-required"
                label="Required"
                defaultValue="Title"
                style={{ paddingBottom: "30px" }}
                value={title}
                onChange={(e) => setTitle(e.target.value)}
              />
              <TextField
                required
                id="standard-required"
                label="Required"
                defaultValue="Description"
                value={description}
                onChange={(e) => setDesc(e.target.value)}
              />

              {selectedRows.current.rows.length > 0 ? (
                <Button
                  color="primary"
                  variant="contained"
                  style={{ marginTop: "50px" }}
                  onClick={() => {
                    handleEditItem(selectedRows.current.rows[0]);
                    setShowAdd(false);
                    setDesc(null);
                    setTitle(null);
                  }}
                  disabled={!description || !title}
                >
                  SAVE
                </Button>
              ) : (
                <Button
                  color="primary"
                  variant="contained"
                  style={{ marginTop: "50px" }}
                  onClick={() => {
                    handleAddItem(title, description);
                    setShowAdd(false);
                    setDesc(null);
                    setTitle(null);
                  }}
                  disabled={!description || !title}
                >
                  ADD
                </Button>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  };

  const handleRemoveItem = (itemIds) => {
    FIRESTORE.deleteNotifications(itemIds);
    fetchNotifications();
  };

  const handleAddItem = (title, description) => {
    FIRESTORE.addNotification(title, description);
    fetchNotifications();
  };

  const handleEditItem = (itemForEdit) => {
    const { id } = itemForEdit;
    FIRESTORE.editNotification(id, description, title);
    fetchNotifications();
  };

  return (
    <>
      {showAdd ? (
        renderAddForm()
      ) : (
        <div style={{ height: 400, width: "100%" }}>
          <div style={{ height: 400, width: "100%" }}>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <FormControl style={{ marginLeft: "1rem" }}>
                <InputLabel
                  shrink
                  id="demo-simple-select-placeholder-label-label"
                >
                  Action
                </InputLabel>

                <Select
                  labelId="demo-simple-select-placeholder-label-label"
                  id="demo-simple-select-placeholder-label"
                  value={action}
                  onChange={(e) => {
                    if (selectedRows.current.rows.length > 0) {
                      setAction(e.target.value);
                      switch (e.target.value) {
                        case "edit":
                          const {
                            title,
                            description,
                          } = selectedRows.current.rows[0];
                          setShowAdd(true);
                          setTitle(title);
                          setDesc(description);
                          setAction(null);
                          break;
                        case "delete":
                          const rowsIds = selectedRows.current.rows.map(
                            (row) => row.id
                          );
                          handleRemoveItem(rowsIds);
                          setDesc(null);
                          setTitle(null);
                          setAction(null);
                          break;
                        default:
                          break;
                      }
                    }
                  }}
                  displayEmpty
                >
                  <MenuItem value={"edit"}>EDIT</MenuItem>
                  <MenuItem value={"delete"}>DELETE</MenuItem>
                </Select>
              </FormControl>
              <Button
                color="primary"
                variant="contained"
                style={{ marginRight: "1rem" }}
                onClick={() => {
                  setShowAdd(true);
                }}
              >
                ADD
              </Button>
            </div>

            <DataGrid
              rows={
                notifications.length > 0
                  ? notifications.map((el, index) => {
                      const { data, id } = el;
                      const { title, description } = data;

                      return {
                        id,
                        title,
                        description,
                      };
                    })
                  : []
              }
              columns={columns}
              pageSize={5}
              checkboxSelection
              onSelectionChange={currentlySelected}
              disableSelectionOnClick
            />
          </div>
        </div>
      )}
    </>
  );
};

export default Notifications;
