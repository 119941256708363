import React, { Component } from "react";
import { Router, Route, Switch } from "react-router-dom";

import "./App.css";

import Dashboard from "./components/Dashboard/Dashboard";
import UserRoute from "./components/routes/UserRoute";
import GuestRoute from "./components/routes/GuestRoute";
import Login from "./components/Login/Login";
import AddUser from "./components/Login/AddUser";
import Table from "./components/Table/Table";
import Edit from "./components/Edit/Edit";
import ChangePassword from "./components/ChangePassword/ChangePassword";
import ImageList from "./components/ImageList/ImageList";
import Users from "./components/Notifications/Users";
import Notifications from "./components/Notifications/Notifications";
import CompanyTemplate from "./components/CompanyTemplate";
import UserTemplate from "./components/UserTemplate";

import history from "./history";

class App extends Component {
  render() {
    return (
      <Router history={history}>
        <div className="App">
          <Switch history={history}>
            <UserRoute exact path="/" component={Dashboard} />
            <GuestRoute path="/login" component={Login} />
            <UserRoute path="/adduser" component={AddUser} />
            <UserRoute path="/table" component={Table} />
            <UserRoute path="/edit" component={Edit} />
            <UserRoute path="/add" component={Edit} isAdd={true} />
            <UserRoute path="/images" component={ImageList} isAdd={true} />
            <UserRoute path="/users" component={Users} />
            <UserRoute path="/notifications" component={Notifications} />
            <UserRoute path="/user-template" component={UserTemplate} />
            <UserRoute path="/company-template" component={CompanyTemplate} />
            <Route path="/changepassword" component={ChangePassword} />
          </Switch>
        </div>
      </Router>
    );
  }
}

export default App;
