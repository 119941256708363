import React, { Component } from "react";
import "./Table.css";
import { connect } from "react-redux";
import { getPage } from "../../actions/index";
import { getRow, deleteRow, deleteRows, uploadFile } from "../../actions/index";
import history from "../../history";
import Loader from "react-loader-spinner";
import Modal from "@material-ui/core/Modal";

import {
  Grid,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Button,
  withStyles,
  Snackbar,
  ButtonGroup,
  Input,
  Container,
} from "@material-ui/core";
import MuiAlert from "@material-ui/lab/Alert";
import AddIcon from "@material-ui/icons/Add";
import InsertDriveFileIcon from "@material-ui/icons/InsertDriveFile";
import { DataGrid } from "@material-ui/data-grid";

const useStyles = {
  marginRight: {
    marginRight: "30px",
  },
  buttonAdd: {
    marginLeft: "auto",
  },
  table: {
    cursor: "pointer",
  },
  buttonUpload: {
    marginRight: 20,
  },
  modalClass: {
    width: "100%",
    minHeight: 500,
    backgroundColor: "#fff",
    border: "1px solid #eee",
    padding: 20,
    overflowY: "auto",
    wordBreak: "break-all",
  },
};

const parseDateType = (string) => {
  switch (string) {
    case "int":
      return "number";
    case "float":
      return "number";
    case "tinyint":
      return "tinyint";
    case "datetime":
      return "date";
    case "longtext":
      return "longtext";
    default:
      return "string";
  }
};

class Table extends Component {
  state = {
    showLoading: false,
    showError: false,
    data: null,
    errorMessage: "",
    selectedRows: [],
    currentPage: 1,
    tableName: "",
    activeTable: history.location.pathname,
    actionSelected: "",
    showModal: false,
    file: null,
  };
  changeHandler = (event) => {
    this.setState({ file: event.target.files[0] });
  };
  parseQuery = (queryString) => {
    var query = {};
    var pairs = (queryString[0] === "?"
      ? queryString.substr(1)
      : queryString
    ).split("&");
    for (var i = 0; i < pairs.length; i++) {
      var pair = pairs[i].split("=");
      query[decodeURIComponent(pair[0])] = decodeURIComponent(pair[1] || "");
    }
    return query;
  };

  componentDidMount() {
    let tableName = history.location.pathname.split("/");
    tableName = tableName[tableName.length - 1];
    let query = history.location.search
      ? this.parseQuery(history.location.search)
      : {};
    if (query.page && query.page != this.state.currentPage) {
      this.setState(
        {
          tableName,
          currentPage: parseInt(query.page, 10),
        },
        () => {
          this.props.getPage(tableName, parseInt(query.page, 10));
        },
      );
    } else {
      this.setState(
        {
          tableName,
        },
        () => {
          this.props.getPage(tableName, this.state.currentPage);
        },
      );
    }
  }
  componentWillReceiveProps(nextProps) {
    let query = nextProps.history.location.search
      ? this.parseQuery(nextProps.history.location.search)
      : {};

    let tableName = nextProps.history.location.pathname.split("/");
    tableName = tableName[tableName.length - 1];
    if (
      tableName !== this.state.tableName ||
      (query.page && query.page != this.state.currentPage)
    ) {
      let tableName = history.location.pathname.split("/");
      tableName = tableName[tableName.length - 1];
      this.setState(
        {
          currentPage: parseInt(query.page),
          tableName,
        },
        () => {
          this.props.getPage(tableName, parseInt(query.page, 10));
        },
      );
    }

    if (nextProps.table.isFetching || nextProps.upload.isFetching) {
      // set state show loading...
      this.setState({ showLoading: true });
    }

    if (
      (!this.props.table.fetched &&
        nextProps.table.fetched &&
        nextProps.table.data) ||
      (!this.props.upload.fetched &&
        nextProps.upload.fetched &&
        nextProps.upload.data)
    ) {
      this.setState({ showLoading: false, data: nextProps.table.data });
      if (
        !this.props.upload.fetched &&
        nextProps.upload.fetched &&
        nextProps.upload.data
      ) {
        this.props.getPage(tableName, parseInt(query.page, 10));
      }
    }
    if (nextProps.table.fetched && nextProps.table.error) {
      this.setState({
        showError: true,
        showLoading: false,
        errorMessage: nextProps.table.error,
      });
    }

    if (nextProps.upload.fetched && nextProps.upload.error) {
      this.setState({
        showError: true,
        showLoading: false,
        errorMessage: nextProps.upload.error,
      });
      this.props.getPage(tableName, parseInt(query.page, 10));
    }
  }

  refactoredData = () => {
    return {
      rowCount: this.state.data.count[0].numrows,
      rows: this.state.data.rows,
      columns: this.state.data.schema.map((item) => {
        return {
          field: item.COLUMN_NAME,
          headerName: item.COLUMN_NAME.replace(/_/g, " ").toUpperCase(),
          type: parseDateType(item.DATA_TYPE),
          width: 200,
        };
      }),
    };
  };

  setSelectedRow = ({ rows, schema }, id) => {
    const inputFields = schema
      .filter((item) => {
        if (
          item.COLUMN_NAME === "id" ||
          item.COLUMN_NAME === "created_on" ||
          item.COLUMN_NAME === "updated_on" ||
          item.COLUMN_NAME === "date_joined" ||
          item.COLUMN_NAME === "last_login" ||
          item.COLUMN_NAME === "password" ||
          item.COLUMN_NAME === "date_joined"
        )
          return false;
        return true;
      })
      .map((item) => {
        let type = parseDateType(item.DATA_TYPE);
        return {
          label: item.COLUMN_NAME,
          value:
            id || id === 0
              ? rows[id][item.COLUMN_NAME]
              : type === "tinyint"
              ? false
              : "",
          type,
          required:
            item.COLUMN_NAME === "product_image_local_path"
              ? false
              : item.COLUMN_KEY !== "",
        };
      });

    return {
      tableName: schema[0].TABLE_NAME,
      selectedRowId: id || id === 0 ? rows[id].id : null,
      inputFields,
      inputFieldsPrevValues: JSON.parse(JSON.stringify(inputFields)),
    };
  };

  renderResponse() {
    let { upload } = this.props;
    if (upload.data || upload.error) {
      if (upload.data) {
        return (
          <>
            <h4>{`Rows With Good Formatting - ${
              upload.data.rowsImported.length
            }: ${upload.data.rowsImported.join(", ")}`}</h4>
            <h4>{`Rows With Bad Formatting - ${
              upload.data.rowsSkipped.length
            }: ${upload.data.rowsSkipped.join(", ")}`}</h4>
            <h4>{`Rows Added Database Response:`}</h4>
            {upload.data.results.map((result, ind) => {
              return (
                <p key={ind}>
                  {result.error
                    ? `Error: ${result.msg}`
                    : `Added ID: ${result.data.id}`}
                </p>
              );
            })}
          </>
        );
      } else if (upload.isFetching) {
        return (
          <Loader type="TailSpin" color="#3f51b5" height={80} width={80} />
        );
      }
    }
    return null;
  }
  render() {
    const { classes } = this.props;
    const btnDisabled =
      !this.state.actionSelected || !this.state.selectedRows.length > 0;
    return (
      <>
        <Grid item xs={12}>
          {this.state.showLoading && (
            <div className="loader-wrapper">
              <Loader type="TailSpin" color="#3f51b5" height={80} width={80} />
            </div>
          )}
          {this.state.showError && <div>{this.state.errorMessage}</div>}
          {this.state.data && (
            <div className="table-edit__container">
              <div className="table-edit__header">
                <FormControl className={classes.marginRight}>
                  <InputLabel
                    shrink
                    id="demo-simple-select-placeholder-label-label"
                  >
                    Action
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-placeholder-label-label"
                    id="demo-simple-select-placeholder-label"
                    value={this.state.actionSelected}
                    onChange={(e) =>
                      this.setState({ actionSelected: e.target.value })
                    }
                    displayEmpty
                  >
                    <MenuItem value={"delete"}>Delete</MenuItem>
                  </Select>
                </FormControl>
                <Button
                  color="primary"
                  variant="contained"
                  disabled={btnDisabled}
                  onClick={() => {
                    if (this.state.actionSelected === "delete") {
                      const rowsIds = this.state.selectedRows.map(
                        (row) => row.id,
                      );
                      this.props.deleteRows(this.state.tableName, rowsIds);
                    }
                  }}
                >
                  Go
                </Button>
                <ButtonGroup className={classes.buttonAdd}>
                  {(this.state.tableName === "products" ||
                    this.state.tableName ===
                      "products_product_for_country") && (
                    <Button
                      className={classes.buttonUpload}
                      startIcon={<InsertDriveFileIcon />}
                      color="primary"
                      variant="contained"
                      onClick={() => {
                        this.setState({ showModal: true });
                      }}
                    >
                      Upload
                    </Button>
                  )}
                  <Button
                    startIcon={<AddIcon />}
                    color="primary"
                    variant="contained"
                    onClick={() => {
                      const selectedRowData = this.setSelectedRow(
                        this.props.table.data,
                        null,
                      );
                      this.props.getRow(selectedRowData);
                      history.push(`/add/${this.state.tableName}`);
                    }}
                  >
                    Add
                  </Button>
                </ButtonGroup>
              </div>
              <DataGrid
                className={classes.table}
                rows={this.refactoredData().rows}
                rowCount={this.refactoredData().rowCount}
                columns={this.refactoredData().columns}
                pageSize={100}
                checkboxSelection
                paginationMode="server"
                page={this.state.currentPage}
                disableSelectionOnClick
                onSelectionChange={(newSelection) => {
                  this.setState({ selectedRows: newSelection.rows });
                }}
                onPageChange={(params) => {
                  let tableName = history.location.pathname.split("/");
                  tableName = tableName[tableName.length - 1];
                  let query = this.parseQuery(history.location.search);
                  if (this.state.currentPage.toString() != `${params.page}`) {
                    this.setState({ currentPage: params.page }, () => {
                      this.props.getPage(tableName, params.page);
                      if (query.page != params.page.toString()) {
                        history.push(
                          `${history.location.pathname}?page=${params.page}`,
                        );
                      }
                    });
                  }
                }}
                onRowClick={(params) => {
                  const selectedRowData = this.setSelectedRow(
                    this.props.table.data,
                    params.rowIndex,
                  );
                  history.push(
                    `/edit/${this.state.tableName}?id=${params.data.id}`,
                  );
                  this.props.getRow(selectedRowData);
                }}
              />
            </div>
          )}
          <Snackbar
            open={this.state.showError}
            autoHideDuration={3000}
            onClose={() => this.setState({ showError: false })}
          >
            <MuiAlert elevation={6} variant="filled" severity="error">
              {this.state.errorMessage}
            </MuiAlert>
          </Snackbar>
        </Grid>
        <Modal
          open={this.state.showModal}
          onClose={() => this.setState({ showModal: false })}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Container className={classes.modalClass}>
            <FormControl style={{ display: "flex", width: "100%" }}>
              <InputLabel
                shrink
                id="demo-simple-select-placeholder-label-label"
              >
                Upload CSV File
              </InputLabel>
              <Input type="file" onChange={this.changeHandler} />
              <Button
                startIcon={<InsertDriveFileIcon />}
                color="primary"
                variant="contained"
                disabled={this.state.file === null}
                onClick={() => {
                  const formData = new FormData();

                  formData.set("file", this.state.file);
                  formData.set("tablename", this.state.tableName);
                  this.props.uploadFile(formData);
                }}
              >
                Upload
              </Button>
            </FormControl>
            <div>{this.renderResponse()}</div>
          </Container>
        </Modal>
      </>
    );
  }
}

function mapStateToProps(state) {
  return {
    table: state.table,
    upload: state.upload,
  };
}

export default connect(
  mapStateToProps,
  {
    getPage,
    getRow,
    deleteRow,
    deleteRows,
    uploadFile,
  },
)(withStyles(useStyles)(Table));
