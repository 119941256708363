import React, { Component, useRef, useState, useEffect } from "react";
import { DataGrid } from "@material-ui/data-grid";
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Button,
} from "@material-ui/core";

import { sendNotifications, getUsers, getNotifications } from "../../actions";

const columns = [
  { field: "id", headerName: "ID", width: 150 },
  { field: "username", headerName: "Username", width: 250 },
  { field: "email", headerName: "Email", width: 250 },
];

const Users = () => {
  const [users, setUsers] = useState([]);
  const [notifications, setNotifications] = useState([]);

  const selectedRows = useRef({});
  const notification = useRef({});

  const fetchNotifications = async () => {
    let res = await getNotifications();

    setNotifications(res);
  };

  useEffect(() => {
    const fetchUsers = async () => {
      let res = await getUsers();

      setUsers(res);
      fetchNotifications();
    };

    fetchUsers();
  }, []);

  const currentlySelected = (selections) => {
    selectedRows.current = selections;
  };

  const setNotification = (e) => {
    notification.current = e;
  };

  return (
    <div style={{ height: 400, width: "100%" }}>
      <div style={{ height: 700, width: "100%" }}>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <FormControl style={{ marginLeft: "1rem" }}>
            <InputLabel shrink id="demo-simple-select-placeholder-label-label">
              Notifications
            </InputLabel>

            <Select
              labelId="demo-simple-select-placeholder-label-label"
              id="demo-simple-select-placeholder-label"
              value={notification.current.title}
              onChange={(e) => setNotification(e.target.value)}
              displayEmpty
            >
              {notifications.map((el, i) => (
                <MenuItem key={i} value={el.data}>
                  Title : {el.data.title} , Desc: {el.data.description}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <Button
            color="primary"
            variant="contained"
            style={{ marginRight: "1rem" }}
            onClick={() => {
              sendNotifications({
                users: selectedRows.current.rows,
                notification: notification.current,
              });
            }}
          >
            Send
          </Button>
        </div>
        <DataGrid
          rows={
            users.length > 0
              ? users.map((el, index) => {
                  return {
                    id: el.uid ? el.uid : index,
                    username: el.username,
                    email: el.email,
                    pushToken: el.pushToken,
                  };
                })
              : []
          }
          columns={columns}
          pageSize={50}
          checkboxSelection
          onSelectionChange={currentlySelected}
          disableSelectionOnClick
        />
      </div>
    </div>
  );
};

export default Users;
