import React from "react";
import { Route, Redirect } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Layout from "../Layout/Layout";

const UserRoute = ({ isAuthenticated, component: Component, ...rest }) => (
  <Route
    {...rest}
    render={(props) =>
      isAuthenticated ? (
        <Layout history={props.history}>
          <Component {...props} {...rest} />
        </Layout>
      ) : (
        <Redirect to="/login" />
      )
    }
  />
);

function mapStateToProps(state) {
  return {
    isAuthenticated: state.user.data && state.user.data.isAuthenticated,
  };
}

export default connect(mapStateToProps)(UserRoute);
