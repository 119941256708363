export const MOCK_DATA = {
  CORE_TABLES: [
    { name: "age_groups", tableName: "" },
    { name: "ethnicities", tableName: "" },
    { name: "fantasy_skin_types", tableName: "" },
    { name: "genders", tableName: "" },
    { name: "ingredients", tableName: "" },
    { name: "lifestyles", tableName: "" },
    { name: "products", tableName: "" },
    { name: "regions", tableName: "" },
    { name: "skin_concerns", tableName: "" },
    { name: "skin_types", tableName: "" },
    { name: "countries", tableName: "" },
    { name: "products_product_for_country", tableName: "Products per country" },
  ],
  AUTH_TABLES: [
    { name: "auth_group", tableName: "" },
    { name: "auth_user", tableName: "" },
  ],
};

export const NOTIFICATIONS = [
  { id: 1, name: "notify", value: "text notification" },
  { id: 2, name: "notify1", value: "text notification1" },
];

export const MAP_DATA = {
  auth_group: {
    tables: [
      {
        tableName: "auth_group_permissions",
        id: "permission_id",
        dataTable: "auth_permission",
        displayName: "name",
      },
    ],
    id: "group_id",
  },
  auth_user: {
    tables: [
      {
        tableName: "auth_user_groups",
        id: "group_id",
        dataTable: "auth_group",
        displayName: "name",
      },
      {
        tableName: "auth_user_user_permissions",
        id: "permission_id",
        dataTable: "auth_permission",
        displayName: "name",
      },
    ],
    id: "user_id",
  },
  fantasy_skin_types: {
    tables: [
      {
        tableName: "fantasy_skin_types_age_groups",
        id: "agegroup_id",
        dataTable: "age_groups",
        displayName: "age_group_name",
      },
      {
        tableName: "fantasy_skin_types_ethnicities",
        id: "ethnicity_id",
        dataTable: "ethnicities",
        displayName: "ethnicity_name",
      },
      {
        tableName: "fantasy_skin_types_genders",
        id: "gender_id",
        dataTable: "genders",
        displayName: "gender_name",
      },
      {
        tableName: "fantasy_skin_types_skin_types",
        id: "skintype_id",
        dataTable: "skin_types",
        displayName: "skin_type_name",
      },
    ],
    id: "fantasyskintype_id",
  },
  products: {
    tables: [
      {
        tableName: "products_product_for_age_groups",
        id: "agegroup_id",
        dataTable: "age_groups",
        displayName: "age_group_name",
      },
      {
        tableName: "products_product_for_ethnicities",
        id: "ethnicity_id",
        dataTable: "ethnicities",
        displayName: "ethnicity_name",
      },
      {
        tableName: "products_product_for_genders",
        id: "gender_id",
        dataTable: "genders",
        displayName: "gender_name",
      },
      {
        tableName: "products_product_for_skin_types",
        id: "skintype_id",
        dataTable: "skin_types",
        displayName: "skin_type_name",
      },
      {
        tableName: "products_product_for_regions",
        id: "region_id",
        dataTable: "regions",
        displayName: "region_name",
      },
      {
        tableName: "products_product_for_lifestyles",
        id: "lifestyle_id",
        dataTable: "lifestyles",
        displayName: "lifestyle_name",
      },
      {
        tableName: "products_product_for_skin_concerns",
        id: "skinconcern_id",
        dataTable: "skin_concerns",
        displayName: "skin_concern_name",
      },
      {
        tableName: "products_product_ingredients",
        id: "ingredient_id",
        dataTable: "ingredients",
        displayName: "ingredient_name",
      },
      {
        tableName: "products_product_for_country",
        id: "country_id",
        dataTable: "countries",
        displayName: "country_name",
      },
    ],
    id: "product_id",
  },
  skin_concerns: {
    tables: [
      {
        tableName: "skin_concern_bad_ingredients",
        id: "ingredient_id",
        dataTable: "ingredients",
        displayName: "ingredient_name",
      },
      {
        tableName: "skin_concern_good_ingredients",
        id: "ingredient_id",
        dataTable: "ingredients",
        displayName: "ingredient_name",
      },
    ],
    id: "skinconcern_id",
  },
  skin_types: {
    tables: [
      {
        tableName: "skin_type_bad_ingredients",
        id: "ingredient_id",
        dataTable: "ingredients",
        displayName: "ingredient_name",
      },
      {
        tableName: "skin_type_good_ingredients",
        id: "ingredient_id",
        dataTable: "ingredients",
        displayName: "ingredient_name",
      },
    ],
    id: "skintype_id",
  },
};
export const TABLE_DATA = {
  totalItems: 100,
  rows: [
    { id: 1, lastName: "Snow", firstName: "Jon", age: 35 },
    { id: 2, lastName: "Lannister", firstName: "Cersei", age: 42 },
    { id: 3, lastName: "Lannister", firstName: "Jaime", age: 45 },
    { id: 4, lastName: "Stark", firstName: "Arya", age: 16 },
    { id: 5, lastName: "Targaryen", firstName: "Daenerys", age: null },
    { id: 6, lastName: "Melisandre", firstName: null, age: 150 },
    { id: 7, lastName: "Clifford", firstName: "Ferrara", age: 44 },
    { id: 8, lastName: "Frances", firstName: "Rossini", age: 36 },
    { id: 9, lastName: "Roxie", firstName: "Harvey", age: 65 },
  ],
  columns: [
    { field: "id", headerName: "ID", width: 70 },
    { field: "firstName", headerName: "First name", width: 130 },
    { field: "lastName", headerName: "Last name", width: 130 },
    {
      field: "age",
      headerName: "Age",
      type: "number",
      width: 90,
    },
    {
      field: "fullName",
      headerName: "Full name",
      width: 160,
    },
  ],
};
