import {
  SET_ROW,
  CHANGE_VALUE,
  CLEAR_SELECTED_ROW,
  FETCHING_TABLE,
  FETCHING_ROW,
  DELETING_ROW,
  DELETE_ROW_SUCCESS,
  DELETE_ROW_FAILURE,
  UPDATING_ROW,
  UPDATE_ROW_FAILURE,
  UPDATE_ROW_SUCCESS,
  ADDING_ROW,
  ADD_ROW_SUCCESS,
  ADD_ROW_FAILURE,
} from '../types';

const initialState = {
  isFetching: false,
  fetched: false,
  error: null,
  data: null,
};

export default function(state = initialState, action) {
  switch (action.type) {
    case FETCHING_ROW: {
      return {
        isFetching: true,
        fetched: false,
        error: null,
        data: null,
      };
    }
    case SET_ROW:
      return {
        isFetching: false,
        fetched: true,
        error: null,
        data: action.payload,
      };

    case CHANGE_VALUE:
      const newState = { ...state, error: '' };
      newState.data.inputFields[action.payload.inputIndex].value =
        action.payload.value;
      return newState;
    case DELETING_ROW:
      return {
        isFetching: true,
        fetched: false,
        error: null,
        data: state.data,
      };

    case DELETE_ROW_SUCCESS:
      return {
        isFetching: false,
        fetched: true,
        error: null,
        data: action.payload,
      };

    case DELETE_ROW_FAILURE:
      return {
        isFetching: false,
        fetched: true,
        error: action.payload,
        data: state.data,
      };

    case UPDATING_ROW:
      return {
        isFetching: true,
        fetched: false,
        error: null,
        data: state.data,
      };

    case UPDATE_ROW_SUCCESS:
      return {
        isFetching: false,
        fetched: true,
        error: null,
        data: action.payload,
      };

    case UPDATE_ROW_FAILURE:
      return {
        isFetching: false,
        fetched: true,
        error: action.payload,
        data: state.data,
      };

    case ADDING_ROW:
      return {
        isFetching: true,
        fetched: false,
        error: null,
        data: state.data,
      };

    case ADD_ROW_SUCCESS:
      return {
        isFetching: false,
        fetched: true,
        error: null,
        data: action.payload,
      };

    case ADD_ROW_FAILURE:
      return {
        isFetching: false,
        fetched: true,
        error: action.payload,
        data: state.data,
      };

    default:
      return state;
  }
}
