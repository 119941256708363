import React, { Component } from 'react';
import './ChangePassword.css';
import history from '../../history';
import { connect } from 'react-redux';
import {
  Container,
  Box,
  TextField,
  withStyles,
  Button,
  Typography,
  Snackbar,
} from '@material-ui/core';
import MuiAlert from '@material-ui/lab/Alert';
import { changePassword } from '../../actions';
const useStyles = {
  container: {
    paddingTop: '50px',
  },
  box: {
    border: '1px solid #e1e1e1',
  },
  formTitle: {
    // fontSize: "3rem",
    color: '#fff',
    padding: '10px 20px',
  },
  formContent: {
    padding: '40px',
  },
  input: {
    marginBottom: '30px',
  },
  fontSize: {
    // fontSize: "2rem",
  },
};

class ChangePassword extends Component {
  state = {
    pass: '',
    newPass: '',
    newPassConfirm: '',
  };

  formValidation = () => {
    const { pass, newPass, newPassConfirm } = this.state;

    //// CHECK if password is true

    if (newPass !== newPassConfirm) {
      return {
        status: 'bad',
        msg: 'New password and New password confirmaton does not match',
      };
    }

    if (newPass.length < 8) {
      return {
        status: 'bad',
        msg: 'Your password must contain at least 8 characters',
      };
    }

    if (newPass.search(/\d/) == -1) {
      return {
        status: 'bad',
        msg: 'Your password must contain at least 1 number',
      };
    }

    if (newPass.search(/[a-zA-Z]/) == -1) {
      return {
        status: 'bad',
        msg: 'Your password must contain at least 1 letter',
      };
    }
    return {
      status: 'ok',
      msg: '',
    };
  };

  render() {
    const { classes } = this.props;
    const { pass, newPass, newPassConfirm } = this.state;
    const buttonDisabled = !newPass || !newPassConfirm;
    return (
      <Container className={classes.container} maxWidth='sm'>
        <Box className={classes.box}>
          <Box
            className={classes.formTitle}
            bgcolor='primary.main'
            color='text.primary'
          >
            <Typography variant='h4'>Password change</Typography>
          </Box>
          <Box
            className={classes.formContent}
            display='flex'
            flexDirection='column'
          >
            <TextField
              value={this.state.newPass}
              onChange={(e) => this.setState({ newPass: e.target.value })}
              className={classes.input}
              InputProps={{
                className: classes.fontSize,
              }}
              InputLabelProps={{
                className: classes.fontSize,
              }}
              id='filled-password-input'
              label='New password'
              type='password'
              variant='filled'
            />
            <TextField
              value={this.state.newPassConfirm}
              onChange={(e) =>
                this.setState({ newPassConfirm: e.target.value })
              }
              className={classes.input}
              InputProps={{
                className: classes.fontSize,
              }}
              InputLabelProps={{
                className: classes.fontSize,
              }}
              id='filled-password-input'
              label='New password confirmation'
              type='password'
              variant='filled'
            />

            <ul className='validation-list'>
              <li>* Your password must contain at least 8 characters.</li>
              <li>* Your password must contain at least 1 number.</li>
              <li>* Your password must contain at least 1 letter.</li>
            </ul>

            <Button
              className={classes.fontSize}
              variant='contained'
              color='primary'
              size='large'
              onClick={() => {
                const { status, msg } = this.formValidation();
                if (status === 'ok') {
                  console.log('pass', this.state.pass, this.state.newPass);
                  this.props.changePassword(this.state.newPass);
                } else {
                  this.setState({
                    showError: true,
                    message: msg,
                  });
                }
              }}
              disabled={buttonDisabled}
            >
              Change Password
            </Button>
          </Box>
        </Box>
        <Snackbar
          open={this.state.showError}
          autoHideDuration={3000}
          onClose={() => this.setState({ showError: false })}
        >
          <MuiAlert elevation={6} variant='filled' severity='error'>
            {this.state.message}
          </MuiAlert>
        </Snackbar>
      </Container>
    );
  }
}

export default connect(null, { changePassword })(
  withStyles(useStyles)(ChangePassword),
);
