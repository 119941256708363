import firebase from "firebase/app";
import "firebase/firestore";

//import "firebase/firestore";
const firebaseConfig = {
  apiKey: "AIzaSyBB21sGpY5NRMiWbgvI-IJB714BS7SWMms",
  authDomain: "aseiko-a9f2c.firebaseapp.com",
  databaseURL: "https://aseiko-a9f2c.firebaseio.com",
  projectId: "aseiko-a9f2c",
  storageBucket: "aseiko-a9f2c.appspot.com",
  messagingSenderId: "902360064580",
  appId: "1:902360064580:web:fed87861deab64bb289211",
  measurementId: "G-2YYPWR3JWH",
};
if (!firebase.apps.length) {
  firebase.initializeApp(firebaseConfig);
}
const dbh = firebase.firestore();

const FIRESTORE = {};

FIRESTORE.getUsers = async () => {
  try {
    let data = await dbh.collection("users").get();

    if (data) {
      return data.docs.map((doc) => doc.data());
    }
  } catch (e) {
    return { isError: true, msg: e.message };
  }
};

FIRESTORE.getNotifications = async () => {
  try {
    let data = await dbh.collection("notifications").get();

    if (data) {
      return data.docs.map((doc) => {
        return {
          id: doc.id,
          data: doc.data(),
        };
      });
    }
  } catch (e) {
    return { isError: true, msg: e.message };
  }
};

FIRESTORE.deleteNotifications = (docIds) => {
  docIds.map((el) =>
    dbh
      .collection("notifications")
      .doc(el)
      .delete()
      .then(() => {
        console.log("Document successfully deleted!");
      })
      .catch((error) => {
        console.error("Error removing document: ", error);
      })
  );
};

FIRESTORE.addNotification = async (title, description) => {
  try {
    const res = await dbh.collection("notifications").add({
      title,
      description,
    });
    return res;
  } catch (error) {
    console.log(error);
  }
};

FIRESTORE.editNotification = async (docId, title, description) => {
  try {
    const res = await dbh
      .collection("notifications")
      .doc(docId)
      .update({ title, description });

    return res;
  } catch (error) {
    console.log(error);
  }
};

FIRESTORE.addTemplate = async (template, collection) => {
  try {
    const res = await dbh.collection(collection).add({
      template,
    });
    return res;
  } catch (error) {
    console.log(error);
  }
};

FIRESTORE.getTemplates = async (collection) => {
  try {
    let data = await dbh.collection(collection).get();

    if (data) {
      return data.docs.map((doc) => {
        return {
          id: doc.id,
          data: doc.data(),
        };
      });
    }
  } catch (e) {
    return { isError: true, msg: e.message };
  }
};

FIRESTORE.editTemplate = async (docId, template, collection, product) => {
  try {
    const res = await dbh
      .collection(collection)
      .doc(docId)
      .update({ template, product });

    return res;
  } catch (error) {
    console.log(error);
  }
};

export default FIRESTORE;
