import { UPLOADING_FILE, SET_FILE_FAILURE, SET_FILE_SUCCESS } from '../types';
const initialState = {
  isFetching: false,
  fetched: false,
  error: null,
  data: null,
};

export default function(state = initialState, action) {
  switch (action.type) {
    case SET_FILE_SUCCESS:
      return {
        isFetching: false,
        fetched: true,
        error: null,
        data: action.payload,
      };

    case UPLOADING_FILE:
      return {
        isFetching: true,
        fetched: false,
        error: null,
        data: null,
      };
    case SET_FILE_FAILURE:
      return {
        data: null,
        isFetching: false,
        fetched: true,
        error: action.payload,
      };

    default:
      return state;
  }
}
