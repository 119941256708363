import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  Container,
  Box,
  TextField,
  withStyles,
  Button,
  Typography,
  Snackbar,
} from '@material-ui/core';
import MuiAlert from '@material-ui/lab/Alert';
import { loginUser } from '../../actions';

const useStyles = {
  container: {
    paddingTop: '50px',
  },
  box: {
    border: '1px solid #e1e1e1',
  },
  formTitle: {
    // fontSize: "3rem",
    color: '#fff',
    padding: '10px 20px',
  },
  formContent: {
    padding: '40px',
  },
  input: {
    marginBottom: '30px',
  },
  fontSize: {
    // fontSize: "2rem",
  },
};

class Login extends Component {
  state = {
    showLoading: false,
    showError: false,
    message: '',
    username: '',
    password: '',
  };

  componentWillReceiveProps(nextProps) {
    if (nextProps.user.isFetching) {
      // set state show loading...
      this.setState({ showLoading: true });
    }

    if (nextProps.user.fetched && nextProps.user.error) {
      this.setState({
        showError: true,
        showLoading: false,
        message: nextProps.user.error,
      });
    }
  }

  componentDidMount() {
    // const token = localStorage.getItem('aseiko_token');
    // if (token) {
    //   this.props.loginToken(token);
    // }
  }

  loginUser = () => {
    this.props.loginUser(this.state.username, this.state.password);
  };

  buttonDisabled = () => {
    if (this.state.username.length > 0 && this.state.password.length > 0) {
      if (this.state.showLoading) {
        return true;
      }
      return false;
    } else {
      return true;
    }
  };
  render() {
    const { classes } = this.props;
    return (
      <Container className={classes.container} maxWidth='sm'>
        <Box className={classes.box}>
          <Box
            className={classes.formTitle}
            bgcolor='primary.main'
            color='text.primary'
          >
            <Typography variant='h4'>Aseiko Dashboard</Typography>
          </Box>
          <Box
            className={classes.formContent}
            display='flex'
            flexDirection='column'
          >
            <TextField
              value={this.state.username}
              onChange={(e) => this.setState({ username: e.target.value })}
              className={classes.input}
              InputProps={{
                className: classes.fontSize,
              }}
              InputLabelProps={{
                className: classes.fontSize,
              }}
              id='filled-basic'
              label='Username'
              variant='filled'
            />
            <TextField
              value={this.state.password}
              onChange={(e) => this.setState({ password: e.target.value })}
              className={classes.input}
              InputProps={{
                className: classes.fontSize,
              }}
              InputLabelProps={{
                className: classes.fontSize,
              }}
              id='filled-password-input'
              label='Password'
              type='password'
              variant='filled'
            />
            <Button
              className={classes.fontSize}
              variant='contained'
              color='primary'
              size='large'
              onClick={() => this.loginUser()}
              disabled={this.buttonDisabled()}
            >
              Log In
            </Button>
          </Box>
        </Box>
        <Snackbar
          open={this.state.showError}
          autoHideDuration={3000}
          onClose={() => this.setState({ showError: false })}
        >
          <MuiAlert elevation={6} variant='filled' severity='error'>
            {this.state.message}
          </MuiAlert>
        </Snackbar>
      </Container>
    );
  }
}
function mapStateToProps(state) {
  return {
    user: state.user,
  };
}

export default connect(mapStateToProps, { loginUser })(
  withStyles(useStyles)(Login),
);
