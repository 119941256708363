import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  Container,
  Box,
  TextField,
  withStyles,
  Button,
  Typography,
  Snackbar,
} from '@material-ui/core';
import MuiAlert from '@material-ui/lab/Alert';
import { addUser } from '../../actions';

const useStyles = {
  container: {
    paddingTop: '50px',
  },
  box: {
    border: '1px solid #e1e1e1',
  },
  formTitle: {
    // fontSize: "3rem",
    color: '#fff',
    padding: '10px 20px',
  },
  formContent: {
    padding: '40px',
  },
  input: {
    marginBottom: '30px',
  },
  fontSize: {
    // fontSize: "2rem",
  },
};

class AddUser extends Component {
  state = {
    showLoading: false,
    showError: false,
    message: '',
    username: '',
    password: '',
    confirmpassword: '',
  };

  componentWillReceiveProps(nextProps) {
    if (nextProps.user.isFetching) {
      // set state show loading...
      this.setState({ showLoading: true });
    }

    if (nextProps.user.fetched && nextProps.user.error) {
      this.setState({
        showError: true,
        showLoading: false,
        message: nextProps.user.error,
      });
    }
  }

  componentDidMount() {
    // const token = localStorage.getItem('aseiko_token');
    // if (token) {
    //   this.props.loginToken(token);
    // }
  }
  formValidation = () => {
    const { pass, password, confirmpassword } = this.state;
    let newPass = password;
    let newPassConfirm = confirmpassword;

    if (newPass !== newPassConfirm) {
      return {
        status: 'bad',
        msg: 'New password and New password confirmaton does not match',
      };
    }

    if (newPass.length < 8) {
      return {
        status: 'bad',
        msg: 'Your password must contain at least 8 characters',
      };
    }

    if (newPass.search(/\d/) == -1) {
      return {
        status: 'bad',
        msg: 'Your password must contain at least 1 number',
      };
    }

    if (newPass.search(/[a-zA-Z]/) == -1) {
      return {
        status: 'bad',
        msg: 'Your password must contain at least 1 letter',
      };
    }
    return {
      status: 'ok',
      msg: '',
    };
  };
  loginUser = () => {
    this.props.addUser(this.state.username, this.state.password);
  };

  buttonDisabled = () => {
    if (
      this.state.username.length > 0 &&
      this.state.password.length > 0 &&
      this.state.password === this.state.confirmpassword
    ) {
      return false;
    } else {
      return true;
    }
  };
  render() {
    const { classes } = this.props;
    return (
      <Container className={classes.container} maxWidth='sm'>
        <Box className={classes.box}>
          <Box
            className={classes.formTitle}
            bgcolor='primary.main'
            color='text.primary'
          >
            <Typography variant='h4'>Add User</Typography>
          </Box>
          <Box
            className={classes.formContent}
            display='flex'
            flexDirection='column'
          >
            <TextField
              value={this.state.username}
              onChange={(e) => this.setState({ username: e.target.value })}
              className={classes.input}
              InputProps={{
                className: classes.fontSize,
              }}
              InputLabelProps={{
                className: classes.fontSize,
              }}
              id='filled-basic'
              label='Username'
              variant='filled'
            />
            <TextField
              value={this.state.password}
              onChange={(e) => this.setState({ password: e.target.value })}
              className={classes.input}
              InputProps={{
                className: classes.fontSize,
              }}
              InputLabelProps={{
                className: classes.fontSize,
              }}
              id='filled-password-input'
              label='Password'
              type='password'
              variant='filled'
            />
            <TextField
              value={this.state.confirmpassword}
              onChange={(e) =>
                this.setState({ confirmpassword: e.target.value })
              }
              className={classes.input}
              InputProps={{
                className: classes.fontSize,
              }}
              InputLabelProps={{
                className: classes.fontSize,
              }}
              id='filled-password-input'
              label='Confirm Password'
              type='password'
              variant='filled'
            />
            <ul className='validation-list'>
              <li>* Your password must contain at least 8 characters.</li>
              <li>* Your password must contain at least 1 number.</li>
              <li>* Your password must contain at least 1 letter.</li>
            </ul>
            <Button
              className={classes.fontSize}
              variant='contained'
              color='primary'
              size='large'
              onClick={() => {
                const { status, msg } = this.formValidation();
                if (status === 'ok') {
                  this.loginUser();
                } else {
                  this.setState({
                    showError: true,
                    message: msg,
                  });
                }
              }}
              disabled={this.buttonDisabled()}
            >
              Save
            </Button>
          </Box>
        </Box>
        <Snackbar
          open={this.state.showError}
          autoHideDuration={3000}
          onClose={() => this.setState({ showError: false })}
        >
          <MuiAlert elevation={6} variant='filled' severity='error'>
            {this.state.message}
          </MuiAlert>
        </Snackbar>
      </Container>
    );
  }
}
function mapStateToProps(state) {
  return {
    user: state.user,
  };
}

export default connect(mapStateToProps, { addUser })(
  withStyles(useStyles)(AddUser),
);
