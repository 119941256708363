import React from "react";
import ReactDOM from "react-dom";
import App from "./App";

import { BrowserRouter } from "react-router-dom";
import { createBrowserHistory } from "history";
import thunk from "redux-thunk";
import logger from "redux-logger";
import { applyMiddleware, createStore } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";
import { Provider } from "react-redux";
import rootReducer from "./reducers/rootReducer";
import { loginUserToken } from "./actions";
// import { userLoggedIn } from './actions/auth';

const store = createStore(
  rootReducer,
  composeWithDevTools(applyMiddleware(thunk, logger)),
);
console.warn = () => {};
if (localStorage.aseiko_token) {
  try {
    store.dispatch(loginUserToken(localStorage.aseiko_token));
  } catch (err) {
    console.log("LOGIN ERR", err);
    localStorage.removeItem("aseiko_token");
  }
}

ReactDOM.render(
  <BrowserRouter>
    <Provider store={store}>
      <App />
    </Provider>
  </BrowserRouter>,
  document.getElementById("root"),
);
