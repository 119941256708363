import React, { Component } from "react";
import { connect } from "react-redux";
import {
  onChange,
  deleteRow,
  updateRow,
  addRow,
  toggleRelation,
} from "../../actions/index";
import "./Edit.css";
import api from "../../api";
import Loader from "react-loader-spinner";
import {
  TextField,
  ButtonGroup,
  Button,
  Snackbar,
  FormControlLabel,
  Checkbox,
  Select,
  MenuItem,
} from "@material-ui/core";
import MuiAlert from "@material-ui/lab/Alert";
import DeleteIcon from "@material-ui/icons/Delete";
import AddUser from "../Login/AddUser";
import { MAP_DATA } from "../../mock_data";

class Edit extends Component {
  changes = null;
  timeout = null;
  state = {
    showLoading: false,
    showError: false,
    errorMessage: "",
    data: null,
    selectedCountry: null,
  };
  componentDidMount() {
    if (this.props.selectedRow.data) {
      let newState = {
        ...this.state,
        showLoading: false,
        data: this.props.selectedRow.data,
      };
      this.changes = newState.data;
      this.setState(newState);
    }
  }
  componentWillReceiveProps(nextProps) {
    console.log("componentWillReceiveProps", nextProps.selectedRow);
    if (nextProps.selectedRow.isFetching) {
      // set state show loading...
      return this.setState({ showLoading: true });
    }
    if (
      nextProps.selectedRow.fetched &&
      nextProps.selectedRow.data &&
      JSON.stringify(this.props.selectedRow.data) !=
        JSON.stringify(nextProps.selectedRow.data)
    ) {
      this.changes = nextProps.selectedRow.data;
      this.setState({ showLoading: false, data: nextProps.selectedRow.data });
    }
    if (nextProps.selectedRow.fetched && nextProps.selectedRow.error) {
      this.setState({
        showError: true,
        showLoading: false,
        errorMessage: nextProps.selectedRow.error,
      });
    }

    if (
      this.props.selectedRow.isFetching === true &&
      nextProps.selectedRow.isFetching === false
    ) {
      this.setState({
        showLoading: false,
      });
    }
  }

  toggleRelationCheckbox = (id, tableField, table) => {
    let selectedID = this.props.selectedRow.data.selectedRowId;
    let relations = this.state.data.relations;
    if (this.changes != null) {
      relations = { ...this.changes.relations };
    }
    let relationIndexes = relations[table.tableName].tableRelations.map(
      (item) => {
        return item[table.id].toString();
      },
    );
    let newTableRelations = [...relations[table.tableName].tableRelations];
    if (relationIndexes.indexOf(id.toString()) > -1) {
      newTableRelations.splice(relationIndexes.indexOf(id.toString()), 1);
    } else {
      newTableRelations.push({
        [tableField]: selectedID,
        [table.id]: id,
      });
    }
    let newRelations = { ...relations };
    newRelations[table.tableName].tableRelations = newTableRelations;

    this.changes = { ...this.changes, relations: newRelations };

    if (this.timeout) clearTimeout(this.timeout);

    this.timeout = setTimeout(() => {
      this.setState({ data: { ...this.state.data, relations: newRelations } });
    }, 3000);

    // this.setState({ data: { ...this.state.data, relations: newRelations } });
  };
  renderInputType = (type, value, field, index) => {
    const onChnageHandler = (e, field) => {
      const newState = { ...this.state.data };
      newState.inputFields[index].value =
        newState.inputFields[index].label == "is_active"
          ? value === 1
            ? 0
            : 1
          : e.target.value;

      this.changes = { ...newState, ...this.changes };

      if (this.timeout) clearTimeout(this.timeout);

      this.timeout = setTimeout(() => {
        this.setState({ data: { ...newState } });
      }, 3000);
      // this.setState({ data: { ...newState } });
      //this.props.onChange(field, e.target.value, index);
    };
    if (field === "product_image_local_path") {
      if (value) {
        return (
          <img
            style={{ width: 200 }}
            src={`${api.baseUrl}/local/${value}`}
            alt={value}
          />
        );
      } else {
        return null;
      }
    }
    switch (type) {
      case "string":
        return (
          <TextField
            onChange={(e) => onChnageHandler(e, field)}
            id="outlined-basic"
            defaultValue={value}
            variant="outlined"
          />
        );
      case "tinyint":
        return (
          <FormControlLabel
            control={
              <Checkbox
                defaultChecked={value === 1}
                onChange={(e) => {
                  onChnageHandler(e, field);
                  //  this.props.onChange(field, value === 1 ? 0 : 1, index);
                }}
                name="checkedB"
                color="primary"
              />
            }
          />
        );
      case "number":
        return (
          <TextField
            onChange={(e) => onChnageHandler(e, field)}
            id="outlined-basic"
            defaultValue={value}
            variant="outlined"
            type="number"
          />
        );
      case "longtext":
        return (
          <TextField
            onChange={(e) => onChnageHandler(e, field)}
            id="outlined-basic"
            defaultValue={value}
            multiline
            rows={5}
            variant="outlined"
          />
        );

      default:
        return null;
    }
  };

  renderButtons = () => {
    const { deleteRow, updateRow, addRow, selectedRow, isAdd } = this.props;
    if (!this.state.data) {
      return null;
    }
    const { selectedRowId, tableName } = selectedRow.data;
    let inputFieldsCompleted = true;
    let isEmpty = JSON.parse(
      JSON.stringify(this.state.data.inputFields),
    ).filter((item) => item.value === "" && item.required);
    console.log("isEmpty", isEmpty, this.state.data.inputFields);
    if (isEmpty.length) {
      inputFieldsCompleted = false;
    }
    // this.changes.inputFields.forEach((item) => {
    //   if (item.value === '' && item.required) {
    //     inputFieldsCompleted = false;
    //   }
    // });

    if (selectedRowId || selectedRowId === 0)
      return (
        <>
          <Button
            startIcon={<DeleteIcon />}
            variant="contained"
            color="secondary"
            onClick={() => deleteRow(tableName, selectedRowId)}
          >
            Delete
          </Button>

          {selectedRow.data &&
          selectedRow.data.tableName === "auth_user" &&
          !isAdd ? (
            <Button
              variant="contained"
              color="secondary"
              onClick={() => {
                this.props.history.push("/changepassword");
              }}
            >
              Change Password
            </Button>
          ) : null}

          <ButtonGroup color="primary">
            <Button
              onClick={() =>
                updateRow(
                  tableName,
                  selectedRowId,
                  selectedRow.data,
                  "none",
                  this.changes,
                )
              }
            >
              Save and continue editing
            </Button>
            <Button
              onClick={() =>
                updateRow(
                  tableName,
                  selectedRowId,
                  selectedRow.data,
                  "add",
                  this.changes,
                )
              }
            >
              Save and add another
            </Button>
            <Button
              onClick={() =>
                updateRow(
                  tableName,
                  selectedRowId,
                  selectedRow.data,
                  "table",
                  this.changes,
                )
              }
            >
              Save
            </Button>
          </ButtonGroup>
        </>
      );

    return (
      <ButtonGroup color="primary">
        <Button
          disabled={!inputFieldsCompleted}
          onClick={() => {
            addRow(tableName, selectedRow.data, "", this.changes);
          }}
        >
          Save and add another
        </Button>
        <Button
          disabled={!inputFieldsCompleted}
          onClick={() =>
            addRow(tableName, selectedRow.data, "table", this.changes)
          }
        >
          Save
        </Button>
      </ButtonGroup>
    );
  };

  renderInputs = () => {
    return (
      this.props.selectedRow.data &&
      this.props.selectedRow.data.inputFields.map((item, index) => {
        return (
          <div key={index} className="edit__input-box">
            <label>
              {item.label.replace(/_/g, " ").toUpperCase()}
              {item.required ? "*" : ""}:
            </label>
            {this.renderInputType(item.type, item.value, item.label, index)}
          </div>
        );
      })
    );
  };

  saveCheckboxes = () => {
    console.log("saveCheckboxes checked", this.relationChanges);
  };

  handleCountryInputChange = (value, id, table, field) => {
    let relations = this.state.data.relations;
    if (this.changes != null) {
      relations = { ...this.changes.relations };
    }

    let newTableRelations = [
      ...relations[table.tableName].tableRelations.map((rel) => {
        if (rel.country_id === id) {
          rel[field] = value;
        }
        return rel;
      }),
    ];

    let newRelations = { ...relations };
    newRelations[table.tableName].tableRelations = newTableRelations;

    this.changes = { ...this.changes, relations: newRelations };

    if (this.timeout) clearTimeout(this.timeout);

    this.timeout = setTimeout(() => {
      this.setState({ data: { ...this.state.data, relations: newRelations } });
    }, 3000);
  };
  renderRelations = () => {
    let { tableName } = this.state.data;
    let relations = this.state.data ? this.state.data.relations : null;
    console.log("relations", relations);
    if (MAP_DATA[tableName] && relations) {
      return MAP_DATA[tableName].tables.map((table) => {
        let relationIndexes = relations[table.tableName].tableRelations.map(
          (item) => {
            return item[table.id].toString();
          },
        );
        switch (table.tableName) {
          case "products_product_for_country":
            console.log("selctedcountrt", this.state.selectedCountry);
            var selectedCountries = relations[table.tableName].tableData.filter(
              (c) => relationIndexes.indexOf(c.id.toString()) > -1,
            );
            console.log("selectedCountries", selectedCountries);

            return (
              <div
                className="edit__checkboxes-container_large"
                key={table.tableName}
              >
                <div className="edit__checkboxes-text">
                  {table.tableName.toUpperCase().replace("_", " ")}:
                </div>
                <div className="edit__checkboxes-box">
                  <div className="edit__country_row">
                    <Select
                      labelId="country-select-label"
                      id="country-select"
                      value={this.state.selectedCountry}
                      label="Select a country"
                      onChange={(e) => {
                        this.setState({ selectedCountry: e.target.value });
                      }}
                      className={"country_select"}
                    >
                      {relations[table.tableName].tableData.map((c) => {
                        return (
                          <MenuItem
                            key={c.id}
                            value={c.id}
                            disabled={
                              relationIndexes.indexOf(c.id.toString()) > -1
                            }
                          >
                            {c.country_name}
                          </MenuItem>
                        );
                      })}
                    </Select>

                    {this.state.selectedCountry && (
                      <Button
                        onClick={() => {
                          this.toggleRelationCheckbox(
                            this.state.selectedCountry,
                            MAP_DATA[tableName].id,
                            table,
                          );
                          this.setState({ selectedCountry: null });
                        }}
                      >
                        Add Row
                      </Button>
                    )}
                  </div>
                  {relations[table.tableName].tableRelations
                    .map((r) => {
                      var country = selectedCountries.find(
                        (c) => c.id == r.country_id,
                      );
                      r.id = country.id;
                      r.country_name = country.country_name;
                      return r;
                    })
                    .sort((a, b) =>
                      a.country_name
                        .toLowerCase()
                        .localeCompare(b.country_name.toLowerCase()),
                    )
                    .map((c) => {
                      return (
                        <div
                          className="edit__input-country-row"
                          key={c.country_name}
                        >
                          <div
                            className="edit__input-cell"
                            style={{ display: "flex", height: "100%" }}
                          >
                            <label
                              style={{
                                alignSelf: "center",
                                fontWeight: 600,
                                fontSize: 14,
                              }}
                            >
                              {c.country_name}
                            </label>
                          </div>
                          <div className="edit__input-cell">
                            <label>
                              {"product_price".replace(/_/g, " ").toUpperCase()}
                            </label>
                            <TextField
                              onChange={(e) => {
                                this.handleCountryInputChange(
                                  parseFloat(e.target.value),
                                  c.id,
                                  table,
                                  "product_price",
                                );
                              }}
                              id="outlined-basic"
                              defaultValue={c.product_price}
                              variant="outlined"
                              type="number"
                            />
                          </div>

                          <div className="edit__input-cell">
                            <label>
                              {"product_price_currency"
                                .replace(/_/g, " ")
                                .toUpperCase()}
                            </label>
                            <TextField
                              onChange={(e) => {
                                this.handleCountryInputChange(
                                  e.target.value,
                                  c.id,
                                  table,
                                  "product_price_currency",
                                );
                              }}
                              id="outlined-basic"
                              defaultValue={c.product_price_currency}
                              variant="outlined"
                            />
                          </div>
                          <div className="edit__input-cell">
                            <label>
                              {"product_vat_percentage"
                                .replace(/_/g, " ")
                                .toUpperCase()}
                            </label>
                            <TextField
                              onChange={(e) => {
                                this.handleCountryInputChange(
                                  parseFloat(e.target.value),
                                  c.id,
                                  table,
                                  "product_vat_percentage",
                                );
                              }}
                              id="outlined-basic"
                              defaultValue={c.product_vat_percentage}
                              variant="outlined"
                              type="number"
                            />
                          </div>
                          <div className="edit__input-cell">
                            <label>
                              {"product_shipping_cost"
                                .replace(/_/g, " ")
                                .toUpperCase()}
                            </label>
                            <TextField
                              onChange={(e) => {
                                this.handleCountryInputChange(
                                  parseFloat(e.target.value),
                                  c.id,
                                  table,
                                  "product_shipping_cost",
                                );
                              }}
                              id="outlined-basic"
                              defaultValue={c.product_shipping_cost}
                              variant="outlined"
                              type="number"
                            />
                          </div>
                          <div className="edit__input-cell">
                            <label>
                              {"product_coupon"
                                .replace(/_/g, " ")
                                .toUpperCase()}
                            </label>
                            <TextField
                              onChange={(e) => {
                                this.handleCountryInputChange(
                                  parseFloat(e.target.value),
                                  c.id,
                                  table,
                                  "product_coupon",
                                );
                              }}
                              id="outlined-basic"
                              defaultValue={c.product_coupon}
                              variant="outlined"
                              type="number"
                            />
                          </div>
                          <div className="edit__input-cell">
                            <label>
                              {"product_coupon_code"
                                .replace(/_/g, " ")
                                .toUpperCase()}
                            </label>
                            <TextField
                              onChange={(e) => {
                                this.handleCountryInputChange(
                                  e.target.value,
                                  c.id,
                                  table,
                                  "product_coupon_code",
                                );
                              }}
                              id="outlined-basic"
                              defaultValue={c.product_coupon_code}
                              variant="outlined"
                            />
                          </div>
                          <Button
                            color="secondary"
                            onClick={() => {
                              this.toggleRelationCheckbox(
                                c.id,
                                MAP_DATA[tableName].id,
                                table,
                              );
                            }}
                            style={{ alignSelf: "center" }}
                          >
                            Remove
                          </Button>
                        </div>
                      );
                    })}
                </div>
              </div>
            );
          default:
            return (
              <div className="edit__checkboxes-container" key={table.tableName}>
                <div className="edit__checkboxes-text">
                  {table.tableName.toUpperCase().replace("_", " ")}:
                </div>
                <div className="edit__checkboxes-box">
                  {relations[table.tableName].tableData.map((item) => {
                    return (
                      <FormControlLabel
                        key={item.id}
                        control={
                          <Checkbox
                            defaultChecked={
                              relationIndexes.indexOf(item.id.toString()) > -1
                            }
                            onChange={(event) => {
                              this.toggleRelationCheckbox(
                                item.id,
                                MAP_DATA[tableName].id,
                                table,
                              );
                            }}
                            name={"checkbox_" + table.tableName}
                            key={`checkbox_${table.tableName}_${item.id}`}
                            ref={`checkbox_${table.tableName}_${item.id}`}
                            color="primary"
                          />
                        }
                        label={item[table.displayName]}
                      />
                    );
                  })}
                </div>
              </div>
            );
        }
      });
    }

    return null;
  };

  render() {
    let { selectedRow, isAdd } = this.props;
    let { data } = this.state;
    if (!data || this.state.showLoading) {
      return (
        <div className="edit__container">
          <div className="loader-wrapper">
            <Loader type="TailSpin" color="#3f51b5" height={80} width={80} />
          </div>
        </div>
      );
    }
    if (
      selectedRow.data &&
      selectedRow.data.tableName === "auth_user" &&
      isAdd
    ) {
      return <AddUser />;
    }
    return (
      <div className="edit__container">
        <div className="edit__inputs">
          {this.renderInputs()}
          {this.renderRelations()}
        </div>
        <div className="edit__buttons">{this.renderButtons()}</div>
        <Snackbar
          open={this.state.showError}
          autoHideDuration={3000}
          onClose={() => this.setState({ showError: false })}
        >
          <MuiAlert elevation={6} variant="filled" severity="error">
            {this.state.errorMessage}
          </MuiAlert>
        </Snackbar>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    selectedRow: state.selectedRow,
  };
}

export default connect(
  mapStateToProps,
  {
    onChange,
    deleteRow,
    updateRow,
    addRow,
    toggleRelation,
  },
)(Edit);
