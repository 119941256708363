import {
  FETCHING_USER,
  SET_USER_FAILURE,
  SET_USER_SUCCESS,
  FETCHING_DASHBOARD,
  SET_DASHBOARD_FAILURE,
  SET_DASHBOARD_SUCCESS,
  FETCHING_TABLE,
  SET_TABLE_FAILURE,
  SET_TABLE_SUCCESS,
  SET_ROW,
  CHANGE_VALUE,
  CLEAR_SELECTED_ROW,
  FETCHING_ROW,
  DELETING_ROW,
  DELETE_ROW_SUCCESS,
  DELETE_ROW_FAILURE,
  DELETING_ROWS,
  DELETE_ROWS_SUCCESS,
  DELETE_ROWS_FAILURE,
  UPDATING_ROW,
  UPDATE_ROW_SUCCESS,
  UPDATE_ROW_FAILURE,
  ADDING_ROW,
  ADD_ROW_SUCCESS,
  ADD_ROW_FAILURE,
  FETCHING_IMAGES,
  SET_IMAGES_FAILURE,
  SET_IMAGES_SUCCESS,
  UPLOADING_FILE,
  SET_FILE_FAILURE,
  SET_FILE_SUCCESS,
} from "../types";

import api from "../api";
import _ from "lodash";
import history from "../history";
import { MAP_DATA } from "../mock_data";
import FIRESTORE from "../firestore";

export const getUser = (userID) => (dispatch, getState) => {
  dispatch({
    type: FETCHING_USER,
  });
  api.project.getUser(userID).then((user) => {
    if (!user) {
      return dispatch({
        type: SET_USER_FAILURE,
        payload: "Down for maintenance",
      });
    }
    if (user.msg) {
      dispatch({ type: SET_USER_FAILURE, payload: user.msg });

      return;
    }

    dispatch({ type: SET_USER_SUCCESS, payload: user });
  });
};

export const getUserLocal = (userID) => (dispatch, getState) => {
  api.project.getUser(userID).then((user) => {
    if (!user) {
      return dispatch({
        type: SET_USER_FAILURE,
        payload: "Down for maintenance",
      });
    }
    if (user.msg) {
      dispatch(getUser(userID));

      return;
    }

    dispatch({ type: SET_USER_SUCCESS, payload: user });
  });
};

export const getDashboard = (projects) => (dispatch, getState) => {
  let ids = projects
    .filter((item) => item["Project Status"] != "Completed")
    .map((item) => item.id);
  dispatch({
    type: FETCHING_DASHBOARD,
  });
  api.project
    .getDashboard(ids)
    .then((resp) => {
      // console.log('resp', resp);
      if (!resp) {
        return dispatch({
          type: SET_DASHBOARD_FAILURE,
          payload: "Down for maintenance",
        });
      }
      if (resp.msg || resp.statusText) {
        dispatch({ type: SET_DASHBOARD_FAILURE, payload: resp.msg });

        return;
      }
      if (resp.statusText) {
        dispatch({ type: SET_DASHBOARD_FAILURE, payload: resp.statusText });

        return;
      }
      dispatch({ type: SET_DASHBOARD_SUCCESS, payload: resp.data });
    })
    .catch((err) => dispatch({ type: SET_DASHBOARD_FAILURE, payload: err }));
};
export const loginUserToken = (token) => (dispatch, getState) => {
  api.user.token(token).then((user) => {
    if (!user) {
      dispatch({
        type: SET_USER_FAILURE,
        payload: "Down for maintenance",
      });
      return localStorage.removeItem("aseiko_token");
    }
    if (user.msg || user.statusText) {
      dispatch({ type: SET_USER_FAILURE, payload: user.msg });
      localStorage.removeItem("aseiko_token");
      return;
    }

    localStorage.setItem("aseiko_token", user.accessToken);
    dispatch({ type: SET_USER_SUCCESS, payload: user });
    history.push("/");
  });
};
export const loginUser = (username, password) => (dispatch, getState) => {
  dispatch({
    type: FETCHING_USER,
  });
  api.user.login(username, password).then((user) => {
    if (!user) {
      dispatch({
        type: SET_USER_FAILURE,
        payload: "Down for maintenance",
      });
      return localStorage.removeItem("aseiko_token");
    }
    if (user.msg || user.statusText) {
      dispatch({ type: SET_USER_FAILURE, payload: user.msg });
      localStorage.removeItem("aseiko_token");
      return;
    }

    localStorage.setItem("aseiko_token", user.accessToken);
    dispatch({ type: SET_USER_SUCCESS, payload: user });
    history.push("/");
  });
};

export const addUser = (username, password) => (dispatch, getState) => {
  dispatch({
    type: ADDING_ROW,
  });
  let tableName = "auth_user";
  api.tables
    .addUser(username, password)
    .then((resp) => {
      console.log("updateRow RESP", resp);
      if (!resp) {
        return dispatch({
          type: UPDATE_ROW_FAILURE,
          payload: "Down for maintenance",
        });
      }
      if (resp.msg || resp.statusText) {
        dispatch({ type: UPDATE_ROW_FAILURE, payload: resp.msg });

        return;
      }

      dispatch({
        type: ADD_ROW_SUCCESS,
        payload: null,
      });

      history.push(`/table/${tableName}`);
    })
    .catch((err) => {
      dispatch({ type: UPDATE_ROW_FAILURE, payload: err.message });
    });
};

export const changePassword = (newPassword) => (dispatch, getState) => {
  dispatch({
    type: ADDING_ROW,
  });
  let tableName = "auth_user";
  let id = "";
  let user = getState().selectedRow;
  if (user.data) {
    id = user.data.selectedRowId;
  }
  if (!id) {
    dispatch({ type: UPDATE_ROW_FAILURE, payload: "User not found" });
    history.push(`/table/${tableName}`);
  }
  api.tables
    .changePassword(id, newPassword)
    .then((resp) => {
      console.log("updateRow RESP", resp);
      if (!resp) {
        return dispatch({
          type: UPDATE_ROW_FAILURE,
          payload: "Down for maintenance",
        });
      }
      if (resp.msg || resp.statusText) {
        dispatch({ type: UPDATE_ROW_FAILURE, payload: resp.msg });

        return;
      }

      dispatch({
        type: ADD_ROW_SUCCESS,
        payload: null,
      });

      history.push(`/table/${tableName}`);
    })
    .catch((err) => {
      dispatch({ type: UPDATE_ROW_FAILURE, payload: err.message });
    });
};
export const logOutUser = () => (dispatch, getState) => {
  console.log("logOutUser");
  localStorage.removeItem("aseiko_token");
  dispatch({ type: SET_USER_SUCCESS, payload: null });
  history.push("/login");
};

export const getPage = (tableName, tablePageNumber) => (dispatch, getState) => {
  dispatch({
    type: FETCHING_TABLE,
  });
  api.tables.getTable(tableName, tablePageNumber).then((res) => {
    if (!res) {
      return dispatch({
        type: SET_TABLE_FAILURE,
        payload: "Down for maintenance",
      });
    }
    if (res.msg || res.statusText) {
      dispatch({ type: SET_TABLE_FAILURE, payload: res.msg });

      return;
    }

    dispatch({ type: SET_TABLE_SUCCESS, payload: res });
  });
};

export const getRow = (data) => (dispatch, getState) => {
  dispatch({
    type: FETCHING_ROW,
  });

  let identifier = { id: data.selectedRowId };
  let mapData = MAP_DATA[data.tableName];
  if (mapData) {
    api.tables
      .getRowRelations(mapData, identifier)
      .then((resp) => {
        if (!resp) {
          return dispatch({
            type: ADD_ROW_FAILURE,
            payload: "Down for maintenance",
          });
        }
        if (resp.msg || resp.statusText) {
          dispatch({ type: ADD_ROW_FAILURE, payload: resp.msg });

          return;
        }
        data.relations = resp;
        dispatch({
          type: SET_ROW,
          payload: data,
        });
      })
      .catch((err) => {
        dispatch({ type: ADD_ROW_FAILURE, payload: err.message });
      });
  } else {
    dispatch({
      type: SET_ROW,
      payload: data,
    });
  }
};

export const onChange = (fieldName, value, inputIndex) => (
  dispatch,
  getState,
) => {
  dispatch({
    type: CHANGE_VALUE,
    payload: {
      fieldName,
      value,
      inputIndex,
    },
  });
};

export const toggleRelation = (id, tableField, table) => (
  dispatch,
  getState,
) => {
  let data = getState().selectedRow.data;
  let relations = data.relations;
  let relationIndexes = relations[table.tableName].tableRelations.map(
    (item) => {
      return item[table.id].toString();
    },
  );
  let newTableRelations = [...relations[table.tableName].tableRelations];
  if (relationIndexes.indexOf(id.toString()) > -1) {
    newTableRelations.splice(relationIndexes.indexOf(id.toString()), 1);
  } else {
    newTableRelations.push({
      [tableField]: data.selectedRowId,
      [table.id]: id,
    });
  }
  let newSelectedRow = { ...data };
  newSelectedRow.relations[table.tableName].tableRelations = newTableRelations;
  dispatch({
    type: UPDATE_ROW_SUCCESS,
    payload: { ...newSelectedRow },
  });
};

export const deleteRow = (tableName, id) => (dispatch, getState) => {
  dispatch({
    type: DELETING_ROW,
  });

  api.tables
    .deleteRow(tableName, MAP_DATA[tableName], { id })
    .then((resp) => {
      if (!resp) {
        return dispatch({
          type: DELETE_ROW_FAILURE,
          payload: "Down for maintenance",
        });
      }
      if (resp.msg || resp.statusText) {
        dispatch({ type: DELETE_ROW_FAILURE, payload: resp.msg });

        return;
      }

      history.push(`/table/${tableName}`);
      dispatch({
        type: DELETE_ROW_SUCCESS,
        payload: null,
      });
    })
    .catch((err) => {
      dispatch({ type: DELETE_ROW_FAILURE, payload: err.message });
    });
  // dispatch({
  //   type: DELETE_ROW_FAILURE,
  //   payload: 'Down for maintenance',
  // });

  //  dispatch({ type: DELETE_ROW_FAILURE, payload: res.msg });

  //  dispatch({ type: DELETE_ROW_SUCCESS, payload: res);
  //  history.goBack();
  //////////////////////////////////////////////
  //   api.tables.deleteRow(tableName, rowid).then((res) => {

  //     if (!res) {
  //       return dispatch({
  //         type: DELETE_ROW_FAILURE,
  //         payload: "Down for maintenance",
  //       });
  //     }
  //     if (res.msg) {
  //       dispatch({ type: DELETE_ROW_FAILURE, payload: res.msg });

  //       return;
  //     }

  //     dispatch({ type: DELETE_ROW_SUCCESS, payload: res });
  //   })
};

export const deleteRows = (tableName, rows) => (dispatch, getState) => {
  if (!rows.length) {
    return;
  }
  dispatch({
    type: FETCHING_TABLE,
  });

  let identifiers = rows.map((item) => {
    return { id: item };
  });
  api.tables
    .deleteMulti(tableName, MAP_DATA[tableName], identifiers)
    .then((resp) => {
      if (!resp) {
        return dispatch({
          type: DELETE_ROWS_FAILURE,
          payload: "Down for maintenance",
        });
      }
      if (resp.msg || resp.statusText) {
        dispatch({ type: DELETE_ROWS_FAILURE, payload: resp.msg });

        return;
      }
      api.tables.getTable(tableName, 1).then((res) => {
        if (!res) {
          return dispatch({
            type: SET_TABLE_FAILURE,
            payload: "Down for maintenance",
          });
        }
        if (res.msg || res.statusText) {
          dispatch({ type: SET_TABLE_FAILURE, payload: res.msg });

          return;
        }

        dispatch({ type: SET_TABLE_SUCCESS, payload: res });
      });
    })
    .catch((err) => {
      dispatch({ type: DELETE_ROWS_FAILURE, payload: err.message });
    });

  //  dispatch({
  //   type: DELETE_ROWS_SUCCESS,
  //   payload: res,
  // });
};

export const updateRow = (tableName, id, data, redirectTo, changeData) => (
  dispatch,
  getState,
) => {
  dispatch({
    type: UPDATING_ROW,
  });
  console.log("updating", { tableName, id, data });
  let relations = changeData.relations;
  let newRelations = relations ? JSON.parse(JSON.stringify(relations)) : {};
  if (newRelations) {
    Object.keys(newRelations).map((item) => {
      delete newRelations[item].tableData;
    });
  }
  const { inputFields, inputFieldsPrevValues } = data;
  const chnagedValues = inputFields.filter((item, index) => {
    return item.value !== inputFieldsPrevValues[index].value;
  });

  let postData = {};
  data.inputFields.map((item) => {
    postData[item.label] = item.value;
  });
  if (redirectTo === "add") {
    var newData = { ...data };
    newData.selectedRowId = null;
    newData.inputFields = newData.inputFields.map((item) => {
      return { ...item, value: "" };
    });
    Object.keys(newData.relations).map((item) => {
      newData.relations[item].tableRelations = [];
    });
    api.tables
      .updateRow(tableName, postData, newRelations, MAP_DATA[tableName], { id })
      .then((resp) => {
        console.log("updateRow RESP", resp);
        if (!resp) {
          return dispatch({
            type: UPDATE_ROW_FAILURE,
            payload: "Down for maintenance",
          });
        }
        if (resp.msg || resp.statusText) {
          dispatch({ type: UPDATE_ROW_FAILURE, payload: resp.msg });

          return;
        }

        dispatch({ type: UPDATE_ROW_SUCCESS, payload: newData });
        dispatch({
          type: SET_ROW,
          payload: newData,
        });
        history.push(`/add/${data.tableName}`);
      })
      .catch((err) => {
        console.log("ERR 1", err);
        dispatch({ type: UPDATE_ROW_FAILURE, payload: err.message });
      });
  } else {
    var newData = { ...data };

    const newInputFields = newData.inputFieldsPrevValues.map((item, index) => {
      if (item.value !== newData.inputFields[index].value) {
        return newData.inputFields[index];
      } else {
        return item;
      }
    });

    newData.inputFieldsPrevValues = JSON.parse(JSON.stringify(newInputFields));
    api.tables
      .updateRow(tableName, postData, newRelations, MAP_DATA[tableName], { id })
      .then((resp) => {
        console.log("updateRow RESP", resp);
        if (!resp) {
          return dispatch({
            type: UPDATE_ROW_FAILURE,
            payload: "Down for maintenance",
          });
        }
        if (resp.msg || resp.statusText) {
          dispatch({ type: UPDATE_ROW_FAILURE, payload: resp.msg });

          return;
        }

        newData.inputFields = newData.inputFields.map((item) => {
          return { ...item, value: resp.data[item.label] };
        });
        dispatch({ type: UPDATE_ROW_SUCCESS, payload: newData });
        dispatch({
          type: SET_ROW,
          payload: newData,
        });
        if (redirectTo === "table") {
          //  history.push(`/table/${data.tableName}`);
        }
      })
      .catch((err) => {
        console.log("ERR 2", err);
        dispatch({ type: UPDATE_ROW_FAILURE, payload: err.message });
      });
  }
};

export const addRow = (tableName, data, redirectTo, changeData) => (
  dispatch,
  getState,
) => {
  dispatch({
    type: ADDING_ROW,
  });
  let relations = changeData.relations ? changeData.relations : null;
  let newRelations = relations ? JSON.parse(JSON.stringify(relations)) : null;

  if (newRelations) {
    Object.keys(newRelations).map((item) => {
      delete newRelations[item].tableData;
    });
  }
  let postData = {};
  data.inputFields.map((item) => {
    postData[item.label] = item.value;
  });

  api.tables
    .addRow(tableName, newRelations, MAP_DATA[tableName], postData)
    .then((resp) => {
      console.log("updateRow RESP", resp);
      if (!resp) {
        return dispatch({
          type: UPDATE_ROW_FAILURE,
          payload: "Down for maintenance",
        });
      }
      if (resp.msg || resp.statusText) {
        dispatch({ type: UPDATE_ROW_FAILURE, payload: resp.msg });

        return;
      }
      const newData = { ...data };

      newData.inputFields = newData.inputFields.map((item) => {
        return { ...item, value: "" };
      });
      if (newRelations) {
        Object.keys(newData.relations).map((item) => {
          newData.relations[item].tableRelations = [];
        });
      }
      dispatch({
        type: ADD_ROW_SUCCESS,
        payload: newData,
      });

      if (redirectTo === "table") {
        //  history.push(`/table/${data.tableName}`);
      }
    })
    .catch((err) => {
      dispatch({ type: UPDATE_ROW_FAILURE, payload: err.message });
    });
  // dispatch({
  //   type: EDIT_ROW_FAILURE,
  //   payload:  "Down for maintenance",
  //  });
};

export const getTableUsers = (tableName, tablePageNumber) => (
  dispatch,
  getState,
) => {
  api.tables.getTable(tableName, tablePageNumber).then((res) => {
    if (!res) {
      return dispatch({
        type: SET_TABLE_FAILURE,
        payload: "Down for maintenance",
      });
    }
    if (res.msg || res.statusText) {
      dispatch({ type: SET_TABLE_FAILURE, payload: res.msg });

      return;
    }

    dispatch({ type: SET_TABLE_SUCCESS, payload: res });
  });
};

export const getTable = (tableName, tablePageNumber) => (
  dispatch,
  getState,
) => {
  dispatch({
    type: FETCHING_TABLE,
  });

  api.tables.getTable(tableName, tablePageNumber).then((res) => {
    if (!res) {
      return dispatch({
        type: SET_TABLE_FAILURE,
        payload: "Down for maintenance",
      });
    }
    if (res.msg || res.statusText) {
      dispatch({ type: SET_TABLE_FAILURE, payload: res.msg });

      return;
    }

    dispatch({ type: SET_TABLE_SUCCESS, payload: res });

    const selectedRowData = setSelectedRow(res, null);

    // getRow(selectedRowData);
    // dispatch({
    //   type: SET_ROW,
    //   payload: selectedRowData,
    // });
    dispatch(getRow(selectedRowData));
    history.push(`/add/${tableName}`);
  });
};

const parseDateType = (string) => {
  switch (string) {
    case "int":
      return "number";
    case "float":
      return "number";
    case "tinyint":
      return "tinyint";
    case "datetime":
      return "date";
    case "longtext":
      return "longtext";
    default:
      return "string";
  }
};

const setSelectedRow = ({ rows, schema }, id) => {
  const inputFields = schema
    .filter((item) => {
      if (
        item.COLUMN_NAME === "id" ||
        item.COLUMN_NAME === "created_on" ||
        item.COLUMN_NAME === "updated_on" ||
        item.COLUMN_NAME === "date_joined" ||
        item.COLUMN_NAME === "last_login" ||
        item.COLUMN_NAME === "password" ||
        item.COLUMN_NAME === "date_joined"
      )
        return false;
      return true;
    })
    .map((item) => {
      let type = parseDateType(item.DATA_TYPE);
      return {
        label: item.COLUMN_NAME,
        value:
          id || id === 0
            ? rows[id][item.COLUMN_NAME]
            : type === "tinyint"
            ? false
            : "",
        type: parseDateType(item.DATA_TYPE),
        required:
          item.COLUMN_NAME === "product_image_local_path"
            ? false
            : item.COLUMN_KEY !== "",
      };
    });

  return {
    tableName: schema[0].TABLE_NAME,
    selectedRowId: id || id === 0 ? rows[id].id : null,
    inputFields,
    inputFieldsPrevValues: JSON.parse(JSON.stringify(inputFields)),
  };
};
export const getImages = () => (dispatch, getState) => {
  dispatch({
    type: FETCHING_IMAGES,
  });
  api.images
    .getImages()
    .then((resp) => {
      console.log("resp", resp);
      if (!resp) {
        return dispatch({
          type: SET_IMAGES_FAILURE,
          payload: "Down for maintenance",
        });
      }
      if (resp.msg || resp.statusText) {
        dispatch({ type: SET_IMAGES_FAILURE, payload: resp.msg });

        return;
      }
      if (resp.statusText) {
        dispatch({ type: SET_IMAGES_FAILURE, payload: resp.statusText });

        return;
      }
      dispatch({ type: SET_IMAGES_SUCCESS, payload: resp.data });
    })
    .catch((err) => dispatch({ type: SET_IMAGES_FAILURE, payload: err }));
};

export const deleteImage = (image) => (dispatch, getState) => {
  let imageList = getState().images.data.list;
  dispatch({
    type: SET_IMAGES_SUCCESS,
    payload: { list: imageList.filter((item) => item !== image) },
  });
  api.images
    .deleteImages([image])
    .then((resp) => {
      console.log("resp", resp);
      if (!resp) {
        return dispatch({
          type: SET_IMAGES_FAILURE,
          payload: "Down for maintenance",
        });
      }
      if (resp.msg || resp.statusText) {
        dispatch({ type: SET_IMAGES_FAILURE, payload: resp.msg });

        return;
      }
      if (resp.statusText) {
        dispatch({ type: SET_IMAGES_FAILURE, payload: resp.statusText });

        return;
      }
      dispatch({
        type: SET_IMAGES_SUCCESS,
        payload: { list: imageList.filter((item) => item !== image) },
      });
    })
    .catch((err) => dispatch({ type: SET_IMAGES_FAILURE, payload: err }));
};

export const uploadFile = (formData) => (dispatch, getState) => {
  dispatch({
    type: UPLOADING_FILE,
  });
  api.tables
    .sendFile(formData)
    .then((resp) => {
      console.log("resp", resp);
      if (!resp) {
        return dispatch({
          type: SET_FILE_FAILURE,
          payload: "Down for maintenance",
        });
      }
      if (resp.msg || resp.statusText) {
        dispatch({ type: SET_FILE_FAILURE, payload: resp.msg });

        return;
      }
      if (resp.statusText) {
        dispatch({ type: SET_FILE_FAILURE, payload: resp.statusText });

        return;
      }
      dispatch({
        type: SET_FILE_SUCCESS,
        payload: resp,
      });
    })
    .catch((err) => dispatch({ type: SET_FILE_FAILURE, payload: err.message }));
};

export const sendNotifications = (data) => {
  api.notifications
    .sendNotifications(data)
    .then((resp) => console.log(resp))
    .catch((err) => console.log(err));
};

export const getUsers = async () => {
  const users = await FIRESTORE.getUsers();

  return users;
};

export const getNotifications = async () => {
  const notifications = await FIRESTORE.getNotifications();

  return notifications;
};

export const addNotification = (notification) => {};
