import React, { useEffect, useState } from "react";

import { TextareaAutosize, Button } from "@material-ui/core";
import FIRESTORE from "../../firestore";

const CcompanyTemplate = () => {
  const [template, setTemplate] = useState("");
  const [product, setProduct] = useState("");

  const saveTemplate = async () => {
    const res = await FIRESTORE.getTemplates("company-template");
    if (res && res.length) {
      const { id, data } = res[0];

      await FIRESTORE.editTemplate(
        id,
        template !== "" ? template : data.template,
        "company-template",
        product !== "" ? product : data.product
      );
    } else {
      await FIRESTORE.addTemplate({ template, product }, "company-template");
    }
  };

  useEffect(() => {
    const getTemplates = async () => {
      const res = await FIRESTORE.getTemplates("company-template");
      if (res && res.length) {
        const { data } = res[0];
        setTemplate(data.template);
        setProduct(data.product);
      }
    };
    getTemplates();
  }, []);

  return (
    <div style={{ height: 400, width: "100%" }}>
      <div style={{ height: 400, width: "100%" }}>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            flexDirection: "column",
          }}
        >
          <div style={{ paddingLeft: "20px", width: "100%" }}>
            <TextareaAutosize
              aria-label="minimum height"
              rowsMin={20}
              placeholder="HTML TEMPLATE EXAMPLE : <h2>Dear Partner {product_company}<br><br> Our valuable customer {username} has just ordered your product: {product_title},via Aseiko Skin.<br> You are kindly asked to deliver as fast as possible following products from your Brand: <br><br> Customer Address/Delivery Address: {street},State: {state},Country : {country} <br> <h2> <ul>{product}</ul>"
              style={{
                padding: "10px",
                width: "90%",
                resize: "none",
              }}
              onChange={(e) => setTemplate(e.target.value)}
              value={template}
            />
          </div>
          <div style={{ paddingLeft: "20px", width: "100%" }}>
            <TextareaAutosize
              aria-label="minimum height"
              rowsMin={10}
              placeholder="HTML TEMPLATE PRODUCT EXAMPLE : {product_company} {product_title} {product_sku} {product_size_number} {product_size_measurement} {qty} {product_price_currency} {total_price}"
              style={{
                padding: "10px",
                width: "90%",
                resize: "none",
              }}
              onChange={(e) => setProduct(e.target.value)}
              value={product}
            />
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "flex-start",
              paddingLeft: "20px",
              paddingTop: "10px",
            }}
          >
            <Button color="primary" variant="contained" onClick={saveTemplate}>
              Save
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CcompanyTemplate;
