// NOTIFICATIONS
export const SUCCESS_MESSAGE = 'SUCCESS_MESSAGE';
export const ERROR_MESSAGE = 'ERROR_MESSAGE';

//USER

export const FETCHING_USER = 'FETCHING_USER';
export const SET_USER_FAILURE = 'SET_USER_FAILURE';
export const SET_USER_SUCCESS = 'SET_USER_SUCCESS';

//DASHBOARD

export const FETCHING_DASHBOARD = 'FETCHING_DASHBOARD';
export const SET_DASHBOARD_FAILURE = 'SET_DASHBOARD_FAILURE';
export const SET_DASHBOARD_SUCCESS = 'SET_DASHBOARD_SUCCESS';

// TABLE
export const FETCHING_TABLE = 'FETCHING_TABLE';
export const SET_TABLE_FAILURE = 'SET_TABLE_FAILURE';
export const SET_TABLE_SUCCESS = 'SET_TABLE_SUCCESS';

//SELECTED ROW
export const SET_ROW = 'SET_ROW';
export const CHANGE_VALUE = 'CHANGE_VALUE';
export const CLEAR_SELECTED_ROW = 'CLEAR_SELECTED_ROW';
export const FETCHING_ROW = 'FETCHING_ROW';
export const DELETING_ROW = 'DELETING_ROW';
export const DELETE_ROW_SUCCESS = 'DELETE_ROW_SUCCESS';
export const DELETE_ROW_FAILURE = 'DELETE_ROW_FAILURE';
export const DELETING_ROWS = 'DELETING_ROWS';
export const DELETE_ROWS_SUCCESS = 'DELETE_ROWS_SUCCESS';
export const DELETE_ROWS_FAILURE = 'DELETE_ROWS_FAILURE';
export const UPDATING_ROW = 'UPDATING_ROW';
export const UPDATE_ROW_SUCCESS = 'UPDATE_ROW_SUCCESS';
export const UPDATE_ROW_FAILURE = 'UPDATE_ROW_FAILURE';
export const ADDING_ROW = 'ADDING_ROW';
export const ADD_ROW_SUCCESS = 'ADD_ROW_SUCCESS';
export const ADD_ROW_FAILURE = 'ADD_ROW_FAILURE';

//IMAGES
export const FETCHING_IMAGES = 'FETCHING_IMAGES';
export const SET_IMAGES_FAILURE = 'SET_IMAGES_FAILURE';
export const SET_IMAGES_SUCCESS = 'SET_IMAGES_SUCCESS';

//FILES
export const UPLOADING_FILE = 'UPLOADING_FILE';
export const SET_FILE_FAILURE = 'SET_FILE_FAILURE';
export const SET_FILE_SUCCESS = 'SET_FILE_SUCCESS';
