import React, { Component } from "react";
import { connect } from "react-redux";
import { Grid, Button, ButtonGroup, Snackbar } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import EditIcon from "@material-ui/icons/Edit";
import MuiAlert from "@material-ui/lab/Alert";
import history from "../../history";
import { MOCK_DATA } from "../../mock_data";
import { getTable } from "../../actions/index";
import Loader from "react-loader-spinner";

import "./dashboard.css";
import messageIcon from "../../assets/message-square.svg";
import linkIcon from "../../assets/external-link.svg";
import repeatIcon from "../../assets/repeat.svg";
import arrowRight from "../../assets/arrow-right-blue.svg";
import { getProjects, getReports } from "../../actions/index";
import { Link } from "react-router-dom";
import moment from "moment";
import { text } from "body-parser";

class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isSwitchOn: true,
      showLoading: false,
      showError: false,
      errorMessage: "",
      anchorEl: null,
      sidebarMenu: {
        authOpen: false,
        coreappOpen: false,
        imagesOpen: false,
      },
    };
  }
  componentDidMount() {
    document.title = "Aseiko Dashboard";
  }

  componentWillReceiveProps(nextProps) {
    console.log("NEXT PROPS: ", nextProps);
    if (nextProps.table.isFetching) {
      // set state show loading...
      this.setState({ showLoading: true });
    }

    if (nextProps.table.fetched && nextProps.table.data) {
      this.setState({ showLoading: false });
    }
    if (nextProps.table.fetched && nextProps.table.error) {
      this.setState({
        showError: true,
        showLoading: false,
        errorMessage: nextProps.table.error,
      });
    }
  }

  handleClick(event) {
    this.setState({ anchorEl: event.currentTarget });
  }

  handleClose() {
    this.setState({ anchorEl: null });
  }

  render() {
    console.log("RENDER DASHBOARD: ", this.state);
    return (
      <>
        <Grid xs={12} sm={12} md={6} lg={4} item>
          <div className="table-wrapper">
            <div className="table">
              <div className="table__header">
                <Button color="primary" fullWidth>
                  Authentication and Authorization
                </Button>
              </div>
              {MOCK_DATA.AUTH_TABLES.map((table, index) => (
                <div key={index} className="table__item">
                  <Button
                    onClick={() => {
                      history.push(
                        `/table/${table.name
                          .replace(/\s+/g, "-")
                          .toLowerCase()}?page=1`,
                      );
                    }}
                    size="small"
                  >
                    {table.name.replace(/_/g, " ")}
                  </Button>
                  <div className="table__buttons-wrapper">
                    <ButtonGroup
                      variant="outlined"
                      color="secondary"
                      size="small"
                    >
                      <Button
                        onClick={() => {
                          this.props.getTable(
                            `${table.name.replace(/\s+/g, "-").toLowerCase()}`,
                            1,
                          );
                        }}
                        startIcon={<AddIcon />}
                      >
                        Add
                      </Button>
                      <Button
                        onClick={() => {
                          history.push(
                            `/table/${table.name
                              .replace(/\s+/g, "-")
                              .toLowerCase()}?page=1`,
                          );
                        }}
                        startIcon={<EditIcon />}
                      >
                        Change
                      </Button>
                    </ButtonGroup>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </Grid>
        <Grid xs={12} sm={12} md={6} lg={4} item>
          <div className="table-wrapper">
            <div className="table">
              <div className="table__header">
                <Button color="primary" fullWidth>
                  Coreapp
                </Button>
              </div>
              {MOCK_DATA.CORE_TABLES.map((table, index) => (
                <div key={index} className="table__item">
                  <Button
                    onClick={() => {
                      history.push(
                        `/table/${table.name
                          .replace(/\s+/g, "-")
                          .toLowerCase()}?page=1`,
                      );
                    }}
                    size="small"
                  >
                    {table.tableName
                      ? table.tableName
                      : table.name.replace(/_/g, " ")}
                  </Button>
                  <div className="table__buttons-wrapper">
                    <ButtonGroup
                      variant="outlined"
                      color="secondary"
                      size="small"
                    >
                      <Button
                        onClick={() => {
                          this.props.getTable(
                            `${table.name.replace(/\s+/g, "-").toLowerCase()}`,
                            1,
                          );
                        }}
                        startIcon={<AddIcon />}
                      >
                        Add
                      </Button>
                      <Button
                        onClick={() => {
                          history.push(
                            `/table/${table.name
                              .replace(/\s+/g, "-")
                              .toLowerCase()}?page=1`,
                          );
                        }}
                        startIcon={<EditIcon />}
                      >
                        Change
                      </Button>
                    </ButtonGroup>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </Grid>
        <Grid xs={12} sm={12} md={6} lg={4} item>
          <div className="table-wrapper">
            <div className="table">
              <div className="table__header">
                <Button color="primary" fullWidth>
                  IMAGES
                </Button>
              </div>

              <div className="table__item">
                <Button
                  onClick={() => {
                    history.push(`/images`);
                  }}
                  size="small"
                >
                  View Images
                </Button>
              </div>
            </div>
          </div>
        </Grid>
        <Snackbar
          open={this.state.showError}
          autoHideDuration={3000}
          onClose={() => this.setState({ showError: false })}
        >
          <MuiAlert elevation={6} variant="filled" severity="error">
            {this.state.errorMessage}
          </MuiAlert>
        </Snackbar>
      </>
    );
  }
}
function mapStateToProps(state) {
  return {
    table: state.table,
  };
}

export default connect(
  mapStateToProps,
  { getTable },
)(Dashboard);
