import {
    FETCHING_TABLE,
    SET_TABLE_FAILURE,
    SET_TABLE_SUCCESS,
  } from '../types';


 

  const initialState = {
    data: null,
    isFetching: false,
    fetched: false,
    error: null,
  };


  export default function(state = initialState, action) {
    switch (action.type) {
      case SET_TABLE_SUCCESS:
        return {
          isFetching: false,
          fetched: true,
          error: null,
          data: action.payload,
        };
  
      case FETCHING_TABLE:
        return {
          data: state.data,
          isFetching: true,
          fetched: false,
          error: null,
        };
      case SET_TABLE_FAILURE:
        return {
          data: null,
          isFetching: false,
          fetched: true,
          error: action.payload,
        };
  
      default:
        return state;
    }
  }