import { combineReducers } from 'redux';

import user from './user';
import dashboard from './dashboard';
import table from './table';
import selectedRow from './selectedRow';
import images from './images';
import upload from './upload';
export default combineReducers({
  user,
  dashboard,
  table,
  selectedRow,
  images,
  upload,
});
