import React, { Component } from "react";
import "./Layout.css";
import history from "../../history";
import { connect } from "react-redux";
import { getPage, logOutUser } from "../../actions/index";
import { Link } from "react-router-dom";
import {
  Typography,
  Toolbar,
  withStyles,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  Divider,
  Collapse,
  Box,
  Grid,
  Button,
  Menu,
  MenuItem,
} from "@material-ui/core";
import PersonIcon from "@material-ui/icons/Person";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import MenuIcon from "@material-ui/icons/Menu";
import HelpIcon from "@material-ui/icons/Help";
import { MOCK_DATA } from "../../mock_data";

const useStyles = {
  list: {
    fontSize: "20px",
    height: "calc(100% - 83px)",
    overflowY: "auto",
  },
  nestedList: {
    backgroundColor: "#3f51b5",
  },
  nestedItem: {
    paddingLeft: "40px",
  },
  paddingTB: {
    padding: "20px",
  },
  main: {
    height: "100%",
  },
  container: {
    flex: 1,
    height: "calc(100% - 64px)",
  },
  toolbar: {
    justifyContent: "space-between",
  },
  sidebar: {
    width: "400px",
    ["@media (max-width:1450px)"]: {
      width: "300px",
    },
    backgroundColor: "#303f9f",
    height: "100%",
    color: "#fff",
    transition: "0.2s all ease-in-out",
  },
  sidebarOpen: {
    transform: "translateX(0)!important",
    width: "100%",
    backgroundColor: "#303f9f",
    height: "100%",
    color: "#fff",
    transition: "0.2s all ease-in-out",
  },
  divider: {
    backgroundColor: "#eee",
  },
  dashboard: {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  dashboardContainer: {
    height: "calc(100vh - 230px)",
    overflowY: "auto",
    position: "relative",
  },
  footer: {
    height: "70px",
    backgroundColor: "#3f51b5",
  },
  arrow: {
    color: "#fff",
  },
};

class Layout extends Component {
  constructor(props) {
    super(props);
    this.state = {
      anchorEl: null,
      coreTable: "",
      authTable: "",
      authOpen: false,
      coreappOpen: false,
      menuOpen: false,
      imagesOpen: false,
    };
  }

  componentDidMount() {
    let tableName = history.location.pathname.split("/");
    tableName = tableName[tableName.length - 1];
    let activeCoreTable = MOCK_DATA.CORE_TABLES.filter((table) => {
      return table.name === tableName;
    });
    let activeAuthTable = MOCK_DATA.AUTH_TABLES.filter((table) => {
      return table.name === tableName;
    });
    let newState = {};
    if (activeCoreTable.length && tableName !== this.state.coreTable) {
      newState.coreTable = tableName;
      newState.coreappOpen = true;
    }

    if (activeAuthTable.length && tableName !== this.state.authTable) {
      newState.authTable = tableName;
      newState.authOpen = true;
    }
    this.setState(newState);
  }

  componentWillReceiveProps(nextProps) {
    let tableName = nextProps.history.location.pathname.split("/");
    tableName = tableName[tableName.length - 1];

    let activeCoreTable = MOCK_DATA.CORE_TABLES.filter((table) => {
      return table.name === tableName;
    });
    let activeAuthTable = MOCK_DATA.AUTH_TABLES.filter((table) => {
      return table.name === tableName;
    });
    let newState = {};
    if (activeCoreTable.length && tableName !== this.state.coreTable) {
      newState.coreTable = tableName;
      newState.coreappOpen = true;
    }

    if (activeAuthTable.length && tableName !== this.state.authTable) {
      newState.authTable = tableName;
      newState.authOpen = true;
    }
    this.setState(newState);
  }

  handleClick(event) {
    this.setState({ anchorEl: event.currentTarget });
  }

  handleClose() {
    this.props.logOutUser();
  }
  parseTitle() {
    let tableName = this.props.history.location.pathname.split("/");
    tableName = tableName[tableName.length - 1];

    return tableName
      ? tableName
          .split("_")
          .map((item) => {
            item = item.charAt(0).toUpperCase() + item.slice(1);
            return item;
          })
          .join(" ")
      : "Aseiko Dashboard";
  }
  render() {
    const { classes } = this.props;
    let coreTable = this.state.coreTable;
    let authTable = this.state.authTable;
    return (
      <Box display="flex" flexDirection="column" className={classes.main}>
        <Box>
          <header className="header">
            <Toolbar className={classes.toolbar}>
              <div
                className="header__hamburger-btn"
                onClick={() =>
                  this.setState({ menuOpen: !this.state.menuOpen })
                }
              >
                {<MenuIcon />}
              </div>
              <Link to="/">
                <h4 className="header__logo">Aseiko Dashboard</h4>
              </Link>
              <div>
                <Button
                  className={classes.arrow}
                  startIcon={<PersonIcon />}
                  endIcon={<ArrowDropDownIcon />}
                  aria-controls="simple-menu"
                  aria-haspopup="true"
                  onClick={(e) => this.handleClick(e)}
                >
                  {this.props.user.username}
                </Button>
                <Menu
                  id="simple-menu"
                  anchorEl={this.state.anchorEl}
                  keepMounted
                  open={Boolean(this.state.anchorEl)}
                  onClose={() => this.setState({ anchorEl: false })}
                >
                  <MenuItem
                    onClick={() => {
                      history.push("/");
                    }}
                  >
                    View Site
                  </MenuItem>
                  {/* <MenuItem
                    onClick={() => {
                      history.push('/changepassword');
                    }}
                  >
                    Change Password
                  </MenuItem> */}
                  <MenuItem onClick={() => this.handleClose()}>
                    Log Out
                  </MenuItem>
                </Menu>
              </div>
            </Toolbar>
            <Divider className={classes.divider} />
          </header>
        </Box>
        <Grid className={classes.container} item container>
          <Grid
            className={
              this.state.menuOpen ? classes.sidebarOpen : classes.sidebar
            }
            item
          >
            <Box>
              <Typography className={classes.paddingTB} variant="h4">
                Menu
              </Typography>
            </Box>

            <Divider className={classes.divider} />
            <List disablePadding className={classes.list}>
              <ListItem
                divider
                onClick={() =>
                  this.setState({
                    authOpen: !this.state.authOpen,
                    coreappOpen: this.state.coreappOpen,
                    imagesOpen: this.state.imagesOpen,
                  })
                }
                button
              >
                <ListItemText>Authentication and Authorization</ListItemText>
                <ListItemIcon>
                  {this.state.authOpen ? (
                    <ExpandMoreIcon className={classes.arrow} />
                  ) : (
                    <NavigateNextIcon className={classes.arrow} />
                  )}
                </ListItemIcon>
              </ListItem>
              <Collapse in={this.state.authOpen}>
                <List className={classes.nestedList} disablePadding>
                  {MOCK_DATA.AUTH_TABLES.map((table, index) => (
                    <ListItem
                      selected={table.name == authTable}
                      key={index}
                      divider
                      className={classes.nestedItem}
                      onClick={() => {
                        this.setState({ menuOpen: false });
                        this.props.getPage(table.name, 1);
                        history.push(
                          `/table/${table.name.toLowerCase()}?page=1`
                        );
                      }}
                      button
                    >
                      {table.name.replace(/_/g, " ").toUpperCase()}
                    </ListItem>
                  ))}
                </List>
              </Collapse>
              <ListItem
                divider
                onClick={() =>
                  this.setState({
                    authOpen: this.state.authOpen,
                    coreappOpen: !this.state.coreappOpen,
                    imagesOpen: this.state.imagesOpen,
                  })
                }
                button
              >
                <ListItemText>Coreapp</ListItemText>
                <ListItemIcon>
                  {this.state.coreappOpen ? (
                    <ExpandMoreIcon className={classes.arrow} />
                  ) : (
                    <NavigateNextIcon className={classes.arrow} />
                  )}
                </ListItemIcon>
              </ListItem>
              <Collapse in={this.state.coreappOpen}>
                <List className={classes.nestedList} disablePadding>
                  {MOCK_DATA.CORE_TABLES.map((table, index) => (
                    <Link
                      key={index}
                      to={`/table/${table.name.toLowerCase()}?page=1`}
                    >
                      <ListItem
                        selected={table.name == coreTable}
                        divider
                        className={classes.nestedItem}
                        button
                        onClick={() => {
                          this.setState({ menuOpen: false });
                          this.props.getPage(table.name, 1);
                        }}
                      >
                        {table.name.replace(/_/g, " ").toUpperCase()}
                      </ListItem>
                    </Link>
                  ))}
                </List>
              </Collapse>
              <ListItem
                divider
                onClick={() => {
                  this.setState({
                    authOpen: this.state.authOpen,
                    coreappOpen: this.state.coreappOpen,
                    imagesOpen: !this.state.imagesOpen,
                  });
                  history.push(`/images`);
                }}
                button
              >
                <ListItemText>Images</ListItemText>
                <ListItemIcon>
                  {this.state.imagesOpen ? (
                    <ExpandMoreIcon className={classes.arrow} />
                  ) : (
                    <NavigateNextIcon className={classes.arrow} />
                  )}
                </ListItemIcon>
              </ListItem>
              <ListItem
                divider
                onClick={() => {
                  this.setState({
                    authOpen: false,
                    coreappOpen: false,
                    imagesOpen: false,
                  });
                  history.push(`/users`);
                }}
                button
              >
                <ListItemText>Users</ListItemText>
                <ListItemIcon>
                  <NavigateNextIcon className={classes.arrow} />
                </ListItemIcon>
              </ListItem>
              <ListItem
                divider
                onClick={() => {
                  this.setState({
                    authOpen: false,
                    coreappOpen: false,
                    imagesOpen: false,
                  });
                  history.push(`/notifications`);
                }}
                button
              >
                <ListItemText>Notifications</ListItemText>
                <ListItemIcon>
                  <NavigateNextIcon className={classes.arrow} />
                </ListItemIcon>
              </ListItem>
              <ListItem
                divider
                onClick={() => {
                  this.setState({
                    authOpen: false,
                    coreappOpen: false,
                    imagesOpen: false,
                  });
                  history.push(`/company-template`);
                }}
                button
              >
                <ListItemText>Templates Company</ListItemText>
                <ListItemIcon>
                  <NavigateNextIcon className={classes.arrow} />
                </ListItemIcon>
              </ListItem>
              <ListItem
                divider
                onClick={() => {
                  this.setState({
                    authOpen: false,
                    coreappOpen: false,
                    imagesOpen: false,
                  });
                  history.push(`/user-template`);
                }}
                button
              >
                <ListItemText>Templates User</ListItemText>
                <ListItemIcon>
                  <NavigateNextIcon className={classes.arrow} />
                </ListItemIcon>
              </ListItem>
            </List>
          </Grid>
          <Grid className={classes.dashboard} item>
            <Box className={classes.dashbaordHeader}>
              <Typography className={classes.paddingTB} variant="h4">
                {this.parseTitle()}
              </Typography>
              <Divider />
            </Box>
            <Grid className={classes.dashboardContainer} container>
              {this.props.children}
            </Grid>
            <Box className={classes.footer}>
              <div className="footer-content">
                <a
                  href="https://gitlab.com/moshfiqur/aseiko-dashboard/-/issues"
                  className="footer-support-link"
                  target="_blank"
                >
                  <HelpIcon fontSize="small" /> Support
                </a>
                <div className="footer-text">
                  Aseiko Dashboard
                  <br /> Developed by{" "}
                  <a href="https://dashboard.aseiko.me/" target="_blank">
                    aseiko.com
                  </a>
                </div>
              </div>
            </Box>
          </Grid>
        </Grid>
      </Box>
    );
  }
}
function mapStateToProps(state) {
  return {
    user: state.user.data,
  };
}

export default connect(
  mapStateToProps,
  { getPage, logOutUser }
)(withStyles(useStyles)(Layout));
